import React, { FC, ReactElement } from 'react';
import { Button } from '@consta/uikit/Button';
import { NotificationType } from 'types';
import { useNotifications } from 'entities/user-notifications/hooks';
import { useReadNotificationMutation } from '../../entities/user-notifications/api/readNotification.generated';
import { CardsWrapper, NotificationsHeader, Wrapper } from './ui/styled';
import NotificationCard from './ui/notificationCard';

const NotificationsList: FC = () => {
  const { listNotifications, refetch } = useNotifications();
  const [readNotification] = useReadNotificationMutation();

  const renderNotification = (notification: NotificationType): ReactElement => (
    <NotificationCard
      notification={notification}
      readAllNotifications={handleReadAllNotifications}
    />
  );

  const renderNotifications = (): ReactElement => (
    <CardsWrapper>{listNotifications?.map(renderNotification)}</CardsWrapper>
  );

  const getUnreadIds = () =>
    listNotifications
      ?.filter((notify) => !notify.read)
      .map((notify) => notify.id) ?? [];

  const handleReadAllNotifications = async () => {
    await readNotification();
    refetch();
  };

  const renderNotificationsList = (): ReactElement => (
    <>
      <NotificationsHeader>
        <h2>Уведомления</h2>
        {!!getUnreadIds().length && (
          <Button
            label='Отметить все как прочитанные'
            view='clear'
            size='xs'
            onClick={handleReadAllNotifications}
          />
        )}
      </NotificationsHeader>
      {renderNotifications()}
    </>
  );

  return (
    <Wrapper>
      {listNotifications?.length ? (
        renderNotificationsList()
      ) : (
        <p className='empty_message'>Нет уведомлений</p>
      )}
    </Wrapper>
  );
};

export default NotificationsList;
