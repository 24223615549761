import { Button } from '@consta/uikit/Button';
import { Grid, GridItem } from '@consta/uikit/Grid';

interface ProfileActionsProps {
  onReset: () => void;
  onsubmit: () => void;
}

const ProfileActions = ({ onReset, onsubmit }: ProfileActionsProps) => {
  const isTablet = window.innerWidth < 1100 && window.innerWidth >= 640;
  const isPhone = window.innerWidth < 640;
  return (
    <Grid cols={isTablet ? 9 : 7} colGap='xl'>
      <GridItem col={isTablet ? 2 : 3}>
        <Button
          form='round'
          view='secondary'
          label='Отменить'
          size={isPhone ? 's' : 'm'}
          onClick={onReset}
        />
      </GridItem>
      <GridItem col={isTablet ? 3 : 4} colStart={isTablet ? 7 : 4}>
        <Button
          className='buttonBlack'
          form='round'
          label='Сохранить изменения'
          size={isPhone ? 's' : 'm'}
          onClick={onsubmit}
        />
      </GridItem>
    </Grid>
  );
};

export default ProfileActions;
