import React, { FC, useEffect, useState } from 'react';
import { Text } from '@consta/uikit/Text';
import { Button } from '@consta/uikit/Button';
import { IconEdit } from '@consta/uikit/IconEdit';
import { IconCopy } from '@consta/uikit/IconCopy';
import { useNavigate } from 'react-router-dom';
import { useUser } from 'entities/user';
import ProfileForm from 'widgets/profile-from';
import { useUpdateUserMutation } from 'features/detailed-information/api/updateUser.generated';
import ConfirmModal from 'entities/ui/confirmModal';
import { useSendResetCodeMutation } from 'features/forget-password/api/sendResetCode.generated';
import Row from 'shared/ui/components/row';
import { removeAuthToken } from 'shared/api/auth';
import {
  ProfileActionsContainer,
  ProfileHeaderContainer,
  ProfileWrapper,
  TitleContainer,
} from './styled';

const ProfilePage: FC = () => {
  const { user, refetch: refetchUser } = useUser();
  const [formDisabled, setFormDisabled] = useState(true);
  const [isConfirmEmailModalOpen, setIsConfirmEmailModalOpen] = useState(false);
  const [isConfirmPasswordModalOpen, setIsConfirmPasswordModalOpen] =
    useState(false);
  const [confirmModalData, setConfirmModalData] = useState({
    title: '',
    body: '',
    submitTitle: '',
  });
  const isMobile = window.innerWidth < 640;

  const [sendResetCode] = useSendResetCodeMutation();

  const navigate = useNavigate();

  const onOpenForm = () => {
    setFormDisabled(false);
  };

  const onCloseForm = () => {
    setFormDisabled(true);
  };

  const handleCopy = () =>
    navigator.clipboard.writeText(String(user?.id) || '');

  const onLogout = () => {
    removeAuthToken();
    navigate('/login');
  };

  const handleChangePassword = async () => {
    setConfirmModalData({
      title: 'Сменить пароль',
      // eslint-disable-next-line max-len
      body: `На почту ${user?.email} было отправлено письмо для сброса пароля. Перейдите по ссылке и задайте новый пароль для вашего профиля.`,
      submitTitle: 'Понятно',
    });

    if (user?.id) {
      await sendResetCode({
        variables: {
          input: {
            email: user.email,
          },
        },
      });
    }

    setIsConfirmPasswordModalOpen(true);
  };

  const [updateUser, { data, error }] = useUpdateUserMutation();

  const onSubmit = async ({
    name,
    nameEn,
    phone,
    email,
    govId,
  }: {
    name: string;
    nameEn: string;
    phone: string;
    email: string;
    govId: string;
  }) => {
    await updateUser({
      variables: {
        input: {
          name,
          nameEn,
          phone,
          email,
          govId,
        },
      },
    });

    setFormDisabled(true);
  };

  useEffect(() => {
    if (data?.updateUser?.id && !error) {
      async function refetch() {
        await refetchUser();
      }

      refetch();
    }

    if (data && !data?.updateUser?.isEmailConfirmed) {
      setConfirmModalData({
        title: 'Подтвердите email',
        // eslint-disable-next-line max-len
        body: `На почту ${data?.updateUser?.email} было отправлено письмо для подтверждения. Перейдите по ссылке в письме, чтобы подтвердить электронный адрес`,
        submitTitle: 'Понятно',
      });
      setIsConfirmEmailModalOpen(true);
    }
  }, [data, error]);

  const onCloseConfirmModal = () => {
    if (isConfirmPasswordModalOpen) {
      onLogout();
    }

    setIsConfirmPasswordModalOpen(false);
    setIsConfirmEmailModalOpen(false);
  };

  const onSubmitConfirmModal = async () => {
    if (isConfirmPasswordModalOpen) {
      onLogout();
    }

    setIsConfirmPasswordModalOpen(false);
    setIsConfirmEmailModalOpen(false);
  };

  return (
    <>
      <ProfileWrapper>
        <ProfileHeaderContainer>
          <Row justify='flex-start' align='baseline'>
            <TitleContainer>
              <Text
                size={isMobile ? '2xl' : '4xl'}
                view='primary'
                weight='medium'
              >
                Профиль
              </Text>
            </TitleContainer>
            <Text size='s' view='secondary' weight='medium'>
              Ваш id:
            </Text>
            <Text size='m' view='primary' weight='medium'>
              {user?.id}
            </Text>
            <Button
              iconLeft={IconCopy}
              view='clear'
              size='xs'
              onClick={handleCopy}
            />
          </Row>
          <ProfileActionsContainer>
            {formDisabled ? (
              <Button
                className='buttonBlack'
                form='round'
                label='Редактировать'
                size={isMobile ? 'xs' : 's'}
                iconRight={IconEdit}
                onClick={onOpenForm}
              />
            ) : null}
            <Button
              className='buttonBlack'
              form='round'
              label='Сменить пароль'
              size={isMobile ? 'xs' : 's'}
              onClick={handleChangePassword}
            />
          </ProfileActionsContainer>
        </ProfileHeaderContainer>
        {user ? (
          <ProfileForm
            user={user}
            formDisabled={formDisabled}
            onReset={onCloseForm}
            onSubmit={onSubmit}
          />
        ) : null}
      </ProfileWrapper>
      <ConfirmModal
        isModalOpen={isConfirmEmailModalOpen || isConfirmPasswordModalOpen}
        title={confirmModalData.title}
        body={confirmModalData.body}
        submitTitle={confirmModalData.submitTitle}
        onClose={onCloseConfirmModal}
        onSubmit={onSubmitConfirmModal}
      />
    </>
  );
};

export default ProfilePage;
