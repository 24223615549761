import React, { FC, useState } from 'react';
import { Button } from '@consta/uikit/Button';
import { IconAdd } from '@consta/uikit/IconAdd';
import { PackageData } from 'pages/add-package/types';
import { StockCode, StockType } from 'types';
import Margin from 'shared/ui/components/margin';
import Row from 'shared/ui/components/row';
import PackageInformationItem from '../package-information-item';
import PackageInformationCoreanItem from '../package-information-corean-item';
import { PackageDataWithId, PackageErrorWithId } from './types';
import { AddButtonContainer } from './styled';

interface IProps {
  stock: StockType,
  onBack: () => void,
  onFinish: (data: PackageData[]) => void,
};

const PackageInformation: FC<IProps> = ({ stock, onBack, onFinish }: IProps) => {
  const [counter, setCounter] = useState(1);
  const [info, setInfo] = useState<PackageDataWithId[]>([{ id: 0, data: null }]);
  const [errors, setErrors] = useState<PackageErrorWithId[]>([{ id: 0, error: false }]);

  const onChange = (data: PackageDataWithId, error: PackageErrorWithId) => {
    setInfo(info.map((item) => {
      if (item.id === data.id) {
        return data;
      }
      return item;
    }));
    setErrors(errors.map((item) => {
      if (item.id === error.id) {
        return error;
      }
      return item;
    }));
  };

  const onAdd = () => {
    setInfo([...info, { id: counter, data: null }]);
    setErrors([...errors, { id: counter, error: false } ]);
    setCounter(counter + 1);
  };

  const onRemove = (id: number) => {
      setInfo(info.filter(item => item.id !== id));
      setErrors(errors.filter(item => item.id !== id));
  };

  const onClick = () => {
    if (!info.find(item => item.data === null) && !errors.find(item => item.error === true)) {
      onFinish(info.map(item => item.data) as PackageData[]);
    }
  };

  const isMobile = window.innerWidth < 640;
  const isTablet = window.innerWidth < 1100 && window.innerWidth >= 640;
  const smallScreen = isMobile || isTablet;

  return (
    <>
      {
        info.map((item, idx) => (
          <>
            {stock.code === StockCode.Kr
              ? <PackageInformationCoreanItem
                  key={item.id}
                  packageId={item.id}
                  onChange={(data: PackageDataWithId, error: PackageErrorWithId) => onChange(data, error)}
                  isLast={idx === info.length - 1}
                  onRemove={(id: number) => onRemove(id)}
                />
              : <PackageInformationItem
                  key={item.id}
                  packageId={item.id}
                  onChange={(data: PackageDataWithId, error: PackageErrorWithId) => onChange(data, error)}
                  isLast={idx === info.length - 1}
                  onRemove={(id: number) => onRemove(id)}
                />
            }
          </>
        ))
      }
      {info.length < 20 && <Margin mb={smallScreen ? isMobile ? 28 : 40 : 60}>
        <AddButtonContainer>
          <Button
            label='Добавить еще одну посылку'
            size='s'
            view='clear'
            iconLeft={IconAdd}
            onClick={onAdd}
          />
        </AddButtonContainer>
      </Margin>}
      <Row justify={isMobile ? 'space-between' : 'flex-start'} align='center'>
        <Margin mr={24} style={{ width: 'unset' }}>
          <Button
            label='Назад'
            size={isMobile ? 's' : 'm'}
            view='secondary'
            form='round'
            onClick={onBack}
          />
        </Margin>
        <Button
          label='Далее'
          size={isMobile ? 's' : 'm'}
          view='primary'
          form='round'
          className='buttonBlack'
          disabled={!!info.find(item => item.data === null) || !!errors.find(item => item.error === true)}
          onClick={onClick}
        />
        </Row>
    </>
  );
};

export default PackageInformation;