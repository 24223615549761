import { Grid, GridItem } from '@consta/uikit/Grid';
import { Link } from 'react-router-dom';
import { Text, TextProps } from '@consta/uikit/Text';
import Margin from 'shared/ui/components/margin';
import DesktopOnly from 'shared/ui/components/desktopOnly';
import MobileOnly from 'shared/ui/components/mobileOnly';

export type NavigationListItem = {
  label: string;
  href: string;
  active: boolean;
  order: number;
};

export const NavigationList = ({ items }: { items: NavigationListItem[] }) => {
  const renderItems = (textSize: TextProps['size']) => {
    return items
      .sort((a, b) => a.order - b.order)
      .map((item) => {
        return (
          <GridItem key={`${item.label}_${item.href}`}>
            <Link to={item.href}>
              <Text view='secondary' size={textSize}>
                {item.label}
              </Text>
            </Link>
          </GridItem>
        );
      });
  };
  return (
    <Margin ml={48}>
      <DesktopOnly breakpoint={1280}>
        <Grid cols={2} colGap={'xl'} rowGap={'l'}>
          {renderItems('s')}
        </Grid>
      </DesktopOnly>
      <MobileOnly breakpoint={1280}>
        <Grid cols={1} colGap={'xl'} rowGap={'xs'}>
          {renderItems('xs')}
        </Grid>
      </MobileOnly>
    </Margin>
  );
};

export default NavigationList;
