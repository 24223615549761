import React, { FC, useState, useEffect } from 'react';
import { Text } from '@consta/uikit/Text';
import { Card } from '@consta/uikit/Card';
import { Button } from '@consta/uikit/Button';
import { RadioGroup } from '@consta/uikit/RadioGroup';
import { Grid, GridItem } from '@consta/uikit/Grid';
import { ProgressStepBar } from '@consta/uikit/ProgressStepBar';
import { DefaultItem } from '@consta/uikit/__internal__/src/components/ProgressStepBar/helpers';
import { useNavigate } from 'react-router-dom';
import Stock from 'features/add-package/stock';
import { BuyoutStrategy, CreateBuyoutInput, StockType } from 'types';
import RepurchaseForm from 'features/repurchase-form';
import { RepurchaseItemType } from 'entities/repurchase/repurchase-item';
import { useCreateBuyoutsMutation } from 'features/repurchase-form/api/createRepurchase.generated';
import Margin from 'shared/ui/components/margin';
import DesktopOnly from 'shared/ui/components/desktopOnly';
import MobileOnly from 'shared/ui/components/mobileOnly';
import {
  StepBarContainer,
  ContentCard,
  SuccessWrapper,
  MobileRadioGroupWrapper,
} from './styled';

interface RadioItem {
  name: string;
  value: BuyoutStrategy;
}

const RepurchasePage: FC = () => {
  const [activeStep, setActiveStep] = useState(0);

  const [currentStock, setCurrentStock] = useState<StockType | null>(null);
  const [repurchaseData, setRepurchaseData] = useState<RepurchaseItemType[]>(
    [],
  );
  const [createRepurchase] = useCreateBuyoutsMutation();

  const navigate = useNavigate();

  const isMobile = window.innerWidth < 500;

  const initSteps: DefaultItem[] = [
    {
      label: 'Информация о заявке',
      point: 1,
      content: (
        <>
          {(currentStock && (
            <Text size='s' view='secondary'>
              {currentStock?.name}
            </Text>
          )) ||
            null}
        </>
      ),
    },
    {
      label: 'Информация о товарах',
      point: 2,
      content: (
        <>
          {(repurchaseData.length && (
            <Text size='s' view='secondary'>
              Добавлено товаров: {repurchaseData.length} шт
            </Text>
          )) ||
            null}
        </>
      ),
    },
  ];

  const radioItems: RadioItem[] = [
    {
      name: 'Выкупить товар только по указанной цене',
      value: BuyoutStrategy.StatedPrice,
    },
    {
      name: 'Выкупить товар при увеличении цены до 10%',
      value: BuyoutStrategy.UpTo_10,
    },
    {
      name: 'Выкупить товар при увеличении цены до 30%',
      value: BuyoutStrategy.UpTo_30,
    },
    {
      name: 'Выкупить товар в любом случае',
      value: BuyoutStrategy.AnyPrice,
    },
  ];

  const defaultBuyoutStrategy = radioItems[1];

  const [steps, setSteps] = useState<DefaultItem[]>(initSteps);
  const [isFinished, setIsFinished] = useState(false);
  const [buyoutStrategy, setBuyoutStrategy] = useState(defaultBuyoutStrategy);

  useEffect(() => {
    if (currentStock?.id) {
      setSteps(initSteps);
    }
  }, [currentStock]);

  const onFinishStocks = (stock: StockType) => {
    setCurrentStock(stock);
    setActiveStep(activeStep + 1);
    setRepurchaseData([]);
    // setShowStepbar(false);
  };

  const onFinishInfo = (data: RepurchaseItemType[]) => {
    setRepurchaseData(data);
    setShowStepbar(false);
    onFinishDelivery(data);
    setIsFinished(true);
  };

  const renderRadioItems = () => {
    return (
      <>
        <Margin mb={8}>
          <Text size='m' view='primary'>
            Что делать если цена изменится
          </Text>
        </Margin>
        <RadioGroup
          value={buyoutStrategy}
          items={radioItems}
          getItemLabel={(item) => item.name}
          onChange={({ value }) => setBuyoutStrategy(value)}
        />
      </>
    );
  };

  const [showStepbar, setShowStepbar] = useState(true);

  // это какой то ужас, но без этого на 3-4 шаге просто пропадает линия степбара
  useEffect(() => {
    if (!showStepbar) {
      setShowStepbar(true);
    }
  }, [showStepbar]);

  const onFinishDelivery = async (data: RepurchaseItemType[]) => {
    const packagesInput: CreateBuyoutInput[] = data.map((item) => ({
      stockId: currentStock!.id,
      strategy: buyoutStrategy.value,
      summary: {
        name: item.itemName,
        link: item.itemLink,
        description: item.colorAndSize,
        count: item.count || 0,
        cost: item.cost || 0,
      },
    }));

    await createRepurchase({
      variables: {
        input: packagesInput,
      },
    }).then(() => setIsFinished(true));
  };

  return (
    <>
      <Margin mb={32}>
        <Text size='4xl' view='primary' weight='medium'>
          Выкуп товара
        </Text>
      </Margin>
      <Margin mb={60}>
        <StepBarContainer>
          {showStepbar && (
            <ProgressStepBar
              steps={steps}
              activeStepIndex={activeStep}
              getItemLabel={(item) => item.label}
              getItemStatus={(item) => {
                if ((item.point as number) <= activeStep || isFinished) {
                  return 'success';
                }
              }}
              getItemLineStatus={(item) => {
                if ((item.point as number) <= activeStep || isFinished) {
                  return 'success';
                }
              }}
            />
          )}
        </StepBarContainer>
      </Margin>
      <ContentCard>
        <Card shadow={false}>
          {activeStep === 0 && !isFinished && (
            <Grid cols={6} colGap='s'>
              <GridItem col={isMobile ? 6 : 2}>
                <DesktopOnly breakpoint={640}>
                  <Stock
                    caption='Выберите склад, на который вы хотите оформить заказ'
                    onFinish={(stock: StockType) => onFinishStocks(stock)}
                  />
                </DesktopOnly>
                <MobileOnly breakpoint={640}>
                  <MobileRadioGroupWrapper>
                    <Stock
                      caption='Выберите склад, на который вы хотите оформить заказ'
                      onFinish={(stock: StockType) => onFinishStocks(stock)}
                      additionalAction={
                        <Margin mb={18}>{renderRadioItems()}</Margin>
                      }
                    />
                  </MobileRadioGroupWrapper>
                </MobileOnly>
              </GridItem>
              <GridItem col={isMobile ? 6 : 4}>
                <DesktopOnly breakpoint={640}>{renderRadioItems()}</DesktopOnly>
              </GridItem>
            </Grid>
          )}
          {activeStep === 1 && !isFinished && (
            <RepurchaseForm
              onBack={() => setActiveStep(activeStep - 1)}
              onFinish={(data: RepurchaseItemType[]) => onFinishInfo(data)}
            />
          )}
          {isFinished && (
            <SuccessWrapper>
              <div>
                <Text size='3xl' weight='medium' view='primary'>
                  Заявка успешно отправлена
                </Text>
                <Margin mt={12} mb={32}>
                  <Text size='s' view='secondary'>
                    Скоро с вами свяжется наш менеджер для уточнения деталей по
                    заказу
                  </Text>
                </Margin>
                <Button
                  className='buttonBlack'
                  size='s'
                  form='round'
                  label='Вернуться на главную'
                  onClick={() => navigate('/dashboard')}
                />
              </div>
            </SuccessWrapper>
          )}
        </Card>
      </ContentCard>
    </>
  );
};

export default RepurchasePage;
