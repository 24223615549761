import React, { FC, useEffect, useState } from 'react';
import { Text } from '@consta/uikit/Text';
import { Button } from '@consta/uikit/Button';
import { IconAdd } from '@consta/uikit/IconAdd';
import { useAddressesListQuery } from 'features/addresses/api/addressesList.generated';
import { AddressType } from 'types';
import EmptyAddresses from 'features/addresses/emptyAddresses';
import AddressFormModal, {
  SubmitAddressInput,
} from 'features/addresses/addressFromModal';
import { useCreateAddressMutation } from 'features/addresses/api/createAddress.generated';
import AddressItem from 'features/addresses/addressItem';
import { useUpdateAddressMutation } from 'features/addresses/api/updateAddress.generated';
import { useDeleteAddressMutation } from 'features/addresses/api/deleteAddress.generated';
import DesktopOnly from 'shared/ui/components/desktopOnly';
import MobileOnly from 'shared/ui/components/mobileOnly';
import {
  AddressHeaderContainer,
  AddressesWrapper,
  EmptyAddressesContainer,
  AddressContainer,
} from './styled';

const AddressesPage: FC = () => {
  const { data, refetch: refetchAddresses } = useAddressesListQuery();
  const [addresses, setAddresses] = useState<AddressType[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState<AddressType | null>(null);

  useEffect(() => {
    const items = data?.listAddresses;

    if (items?.length) {
      setAddresses(items as [AddressType]);
    }
  }, [data]);

  const handleCloseModal = (): void => {
    setFormData(null);
    setIsModalOpen(false);
  };
  const addNewAddress = (): void => setIsModalOpen(true);

  const [createAddress] = useCreateAddressMutation();
  const [updateAddress] = useUpdateAddressMutation();
  const [deleteAddress] = useDeleteAddressMutation();

  const removeAddress = async (id: number) => {
    const success = await deleteAddress({
      variables: {
        id,
      },
    });

    if (success) {
      setAddresses(addresses.filter((item) => item.id !== id));
    }
  };

  const onSubmit = async ({
    country,
    city,
    street,
    building,
    apartment,
    id,
  }: SubmitAddressInput) => {
    if (id) {
      await updateAddress({
        variables: {
          id,
          input: {
            country,
            address: {
              city,
              street,
              home: building,
              flat: apartment,
            },
          },
        },
      });
    } else {
      await createAddress({
        variables: {
          input: {
            country,
            address: {
              city,
              street,
              home: building,
              flat: apartment,
            },
          },
        },
      });
    }

    await refetchAddresses();
  };

  const onEdit = async (item: AddressType) => {
    setIsModalOpen(true);
    setFormData(item);
  };

  return (
    <>
      <AddressesWrapper>
        <AddressHeaderContainer>
          <Text size='4xl' view='primary' weight='medium'>
            Мои адреса
          </Text>
          <DesktopOnly breakpoint={500}>
            <Button
              className='buttonBlack'
              size='s'
              form='round'
              label='Добавить новый адрес'
              iconRight={IconAdd}
              onClick={addNewAddress}
            />
          </DesktopOnly>
          <MobileOnly breakpoint={500}>
            <Button
              className='buttonBlack'
              size='s'
              form='round'
              onlyIcon
              iconRight={IconAdd}
              onClick={addNewAddress}
            />
          </MobileOnly>
        </AddressHeaderContainer>
        {addresses?.length ? (
          addresses.map((a) => (
            <AddressContainer key={`address-${a.id}`}>
              <AddressItem item={a} onDelete={removeAddress} onEdit={onEdit} />
            </AddressContainer>
          ))
        ) : (
          <EmptyAddressesContainer>
            <EmptyAddresses addNewAddress={addNewAddress} />
          </EmptyAddressesContainer>
        )}
      </AddressesWrapper>
      <AddressFormModal
        isModalOpen={isModalOpen}
        formData={formData}
        closeModal={handleCloseModal}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default AddressesPage;
