import React, { FC, useState, useEffect, ReactElement } from 'react';
import { Card } from '@consta/uikit/Card';
import { Text } from '@consta/uikit/Text';
import { Button } from '@consta/uikit/Button';
import { IconCopy } from '@consta/uikit/IconCopy';
import { Grid, GridItem } from '@consta/uikit/Grid';
import { useNavigate } from 'react-router-dom';
import { useStocksListQuery } from 'features/main/api/stocksList.generated';
import { useUser } from 'entities/user';
import { StockCode } from 'types';
import Row from 'shared/ui/components/row';
import Margin from 'shared/ui/components/margin';
import { StockAddressesWrapper } from './styled';

interface IProps {
  hideLink?: boolean
};

interface IAddressInfoItem {
  label: string,
  value: string,
};

const StockAddresses: FC<IProps> = ({ hideLink }: IProps) => {
  const { data } = useStocksListQuery();
  const [activeTab, setActiveTab] = useState<number | undefined>();
  const [addressItems, setAddressItems] = useState<IAddressInfoItem[]>([]);

  const { user } = useUser();

  useEffect(() => {
    if (data?.stocksList) {
      setAddressItems([]);
      if (typeof activeTab !== 'number') {
        setActiveTab(data.stocksList[0]?.id);
      }

      const current = data.stocksList.find(item => item.id === activeTab)?.address;
      const currentStockCode = data.stocksList.find(item => item.id === activeTab)?.code;

      const [
        userName = '(Ваше имя на английском языке)',
        userLastName = '(Ваша фамилия на английском языке)',
      ] = user?.nameEn?.split(' ') || [];

      const userInfo: IAddressInfoItem[] = [
        { label: 'Name (имя)', value: `${userName} LTL` },
        { label: 'Surname (фамилия)', value: `${userLastName} LTL` },
      ];

      if (currentStockCode === StockCode.Kr) {
        current?.addressFirst && userInfo.push({ label: 'Address line 1 (адрес склада)', value: current?.addressFirst });
        current?.phone && userInfo.push({ label: 'Phone number (телефон)', value: current?.phone });
      } else {
        current?.addressFirst && userInfo.push({ label: 'Address line 1 (адрес склада)', value: current?.addressFirst });
        current?.addressSecond && userInfo.push({ label: 'Address line 2 (номер дома)', value: current?.addressSecond });
        current?.city && userInfo.push({ label: 'City (город)', value: current?.city });
        current?.state && currentStockCode === StockCode.Us && userInfo.push({ label: 'State (штат)', value: current?.state });
        current?.zipCode && userInfo.push({ label: 'Zip code (индекс)', value: current?.zipCode });
        current?.phone && userInfo.push({ label: 'Phone number (телефон)', value: current?.phone });
      }

      setAddressItems(userInfo);
    }
  }, [data, activeTab]);

  const isMobile = window.innerWidth < 640;
  const isTablet = window.innerWidth < 1100 && window.innerWidth >= 640;
  const smallScreen = isMobile || isTablet;

  const renderAddressInfoItem = ({ label, value }: IAddressInfoItem): ReactElement => {
    const handleCopy = () => navigator.clipboard.writeText(value);

    return (
      <Margin mb={14}>
        <Grid
          cols={1}
          breakpoints={{
            s: {
              cols: 2,
            },
          }}
        >
          <GridItem>
            <Text size={isMobile ? '2xs' : 's'} view='secondary'>
              {label}
            </Text>
          </GridItem>
          <GridItem>
            <Text size={isMobile ? 'xs' : 's'} view='primary'>
              {value}&nbsp;
              {value && <Button iconLeft={IconCopy} view='clear' size='xs' onClick={handleCopy}/>}
            </Text>
          </GridItem>
        </Grid>
      </Margin>
    );
  };

  const navigate = useNavigate();

  return (
    <>
      {data?.stocksList && <StockAddressesWrapper>
        <Card
          verticalSpace='l'
          horizontalSpace={isMobile ? 's' : 'l'}
          shadow={false}
        >
          <Text
            size={smallScreen ? isMobile ? 'l' : 'xl' : '2xl'}
            weight='medium'
          >
            Ваш адрес склада
          </Text>
          <Margin mt={20} mb={smallScreen ? 16 : 41}>
            <Row justify='space-between' align='center'>
              <Row justify='flex-start' align='center' className='stocksList'>
                {data.stocksList.map(item => (
                  <Margin key={item.id} mr={8} mb={8} style={{ width: 'unset' }}>
                    <Button
                      view={item.id === activeTab ? 'primary' : 'secondary'}
                      label={item.name}
                      className={item.id === activeTab ? 'buttonBlack' : undefined}
                      size='s'
                      form='round'
                      onClick={() => setActiveTab(item.id)}
                    />
                  </Margin>
                ))}
              </Row>
              {!hideLink && !smallScreen && <Button
                size='s'
                className='buttonBlack'
                form='round'
                label='Инструкция по добавлению посылок'
                onClick={() => navigate('/instructions')}
              />}
            </Row>
          </Margin>
          {addressItems?.length && (
            <Grid
              cols={1}
              breakpoints={{
                m: {
                  cols: 2,
                },
              }}
            >
              <GridItem>
                {addressItems.slice(0, 4).map(item => renderAddressInfoItem(item))}
              </GridItem>
              <GridItem>
                {addressItems.slice(4).map(item => renderAddressInfoItem(item))}
              </GridItem>
            </Grid>
          )}
          {!hideLink && smallScreen && <Margin mt={isMobile ? 14 : 21}>
            <Button
              size='s'
              className='buttonBlack'
              form='round'
              width={isMobile ? 'full' : 'default'}
              label='Инструкция по добавлению посылок'
              onClick={() => navigate('/instructions')}
            />
          </Margin>}
        </Card>
      </StockAddressesWrapper>}
    </>
  );
};

export default StockAddresses;