import { Button } from '@consta/uikit/Button';
import { Text } from '@consta/uikit/Text';
import Margin from 'shared/ui/components/margin';
import DesktopOnly from 'shared/ui/components/desktopOnly';
import MobileOnly from 'shared/ui/components/mobileOnly';
import { NavigationListWrapper } from './styled';

export const HelpSectionText = () => {
  return (
    <NavigationListWrapper>
      <DesktopOnly breakpoint={1280}>
        <Text view={'primary'} size='s'>
          По всем вопросам вы можете обращаться в нашу службу поддержки.
        </Text>
        <Text view={'primary'} size='s'>
          Мы работаем по будням с 9:00 до 19:00 по времени Астаны
        </Text>
      </DesktopOnly>
      <MobileOnly breakpoint={1280}>
        <Text view={'primary'} size='xs'>
          По всем вопросам вы можете обращаться в нашу службу поддержки. Мы
          работаем по будням с 9:00 до 19:00 по времени Астаны
        </Text>
      </MobileOnly>
      <Margin mt={12}>
        <Button
          as='a'
          href='https://wa.me/77771617777'
          target='_blank'
          className='buttonBlack'
          size='s'
          form='round'
          label='Служба поддержки'
        />
      </Margin>
    </NavigationListWrapper>
  );
};
