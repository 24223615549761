import React, { FC, useState } from 'react';
import { Select } from '@consta/uikit/Select';
import { Button } from '@consta/uikit/Button';
import { IconAdd } from '@consta/uikit/IconAdd';
import { AddressType, PickupPointType, StockType } from 'types';
import CountryLabel from 'features/country-label';
import { useAddressesListQuery } from 'features/addresses/api/addressesList.generated';
import AddressFormModal, {
  SubmitAddressInput,
} from 'features/addresses/addressFromModal';
import { useCreateAddressMutation } from 'features/addresses/api/createAddress.generated';
import { usePickupPointsListQuery } from 'features/add-package/api/pickupPointsList.generated';
import { AddButtonContainer } from 'features/add-package/package-information/styled';
import Margin from 'shared/ui/components/margin';
import Row from 'shared/ui/components/row';
import { countriesMap } from 'shared/constants/countries.constants';

interface IProps {
  stock: StockType,
  onCancel: () => void,
  onFinish: (pickupPoint: PickupPointType, addressId?: number) => void,
};

const ChangeAddress: FC<IProps> = ({ stock, onCancel, onFinish }: IProps) => {
  const [currentPickupPoint, setCurrentPickupPoint] = useState<PickupPointType | null>(null);
  const [pickupPointError, setPickupPointError] = useState(false);

  const [currentAddress, setCurrentAddress] = useState<AddressType | null>(null);
  const [addressError, setAddressError] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState<AddressType | null>(null);

  const { data } = usePickupPointsListQuery({
    variables: {
      input: {
        originStockId: stock.id,
      },
    },
  });

  const { data: dataAddresses, refetch: refetchAddresses } = useAddressesListQuery();

  const handleChange = (value: PickupPointType | null) => {
    if (!value?.id) return;

    setCurrentPickupPoint(value);
    setPickupPointError(false);
  };

  const handleBlur = () => {
    if (!currentPickupPoint?.id) {
      setPickupPointError(true);
    }
  };

  const handleAddressChange = (value: AddressType | null) => {
    if (!value?.id) return;

    setCurrentAddress(value);
    setAddressError(false);
  };

  const handleAddressBlur = () => {
    if (!currentAddress?.id) {
      setAddressError(true);
    }
  };

  const onSave = () => {
    if (currentPickupPoint?.id) {
      if (currentPickupPoint?.useAddress && !currentAddress?.id) return;

      onFinish(currentPickupPoint, currentAddress?.id);
    }
  };

  const handleCloseModal = (): void => {
    setFormData(null);
    setIsModalOpen(false);
  };

  const [createAddress] = useCreateAddressMutation();

  const onSubmit = async ({
    country,
    city,
    street,
    building,
    apartment,
  }: SubmitAddressInput) => {
    await createAddress({
      variables: {
        input: {
          country,
          address: {
            city,
            street,
            home: building,
            flat: apartment,
          },
        },
      },
    });

    await refetchAddresses();
  };

  const isMobile = window.innerWidth < 640;
  const isTablet = window.innerWidth < 1100 && window.innerWidth >= 640;
  const smallScreen = isMobile || isTablet;

  return (
    <>
      {data?.pickupPointsList && <Margin mb={24}>
        <Row
          direction={smallScreen ? 'column' : 'row'}
          justify='flex-start'
          align={smallScreen ? 'flex-start' : 'center'}
        >
          <Select
            label='Способ доставки'
            placeholder='Выберите из списка'
            items={data.pickupPointsList}
            form='round'
            size={isMobile ? 's' : 'm'}
            required
            value={currentPickupPoint}
            getItemLabel={(item) => item.name}
            getItemKey={(item) => item.id}
            onChange={({ value }) => handleChange(value)}
            status={pickupPointError ? 'alert' : undefined}
            caption={pickupPointError ? 'Поле не заполнено' : undefined}
            onBlur={handleBlur}
            style={{ maxWidth: isMobile ? '100%' : '264px' }}
          />
          {currentPickupPoint?.useAddress && (
            <>
              {dataAddresses?.listAddresses && (
                <Margin
                  ml={smallScreen ? 0 : 24}
                  mt={smallScreen ? 24 : 0}
                >
                  <Row
                    direction={isMobile ? 'column' : 'row'}
                    justify='flex-start'
                    align='flex-start'
                  >
                    <Select
                      label='Адрес доставки'
                      placeholder='Выберите из сохраненных адресов'
                      items={dataAddresses?.listAddresses}
                      form='round'
                      size={isMobile ? 's' : 'm'}
                      required
                      value={currentAddress}
                      getItemLabel={(item) => `${countriesMap[item.country]}, г. ${item.address.city}, ул.${item.address.street} ${item.address.home}, кв. ${item.address.flat}`}
                      getItemKey={(item) => item.id}
                      onChange={({ value }) => handleAddressChange(value)}
                      status={addressError ? 'alert' : undefined}
                      caption={addressError ? 'Поле не заполнено' : undefined}
                      onBlur={handleAddressBlur}
                      renderItem={({
                        item,
                        active,
                        hovered,
                        onClick,
                        onMouseEnter,
                      }) => (
                        <CountryLabel
                          item={{label: `${countriesMap[item.country]}, г. ${item.address.city}, ул.${item.address.street} ${item.address.home}, кв. ${item.address.flat}`, value: item.country}}
                          active={active}
                          hovered={hovered}
                          onClick={onClick}
                          onMouseEnter={onMouseEnter}
                        />
                      )}
                    />
                    <Margin ml={isMobile ? 0 : 8} mt={isMobile ? 12 : 0} style={{ width: 'unset' }}>
                      {isMobile ? <AddButtonContainer>
                        <Button
                          label='Добавить новый адрес'
                          size='s'
                          view='clear'
                          onClick={() => setIsModalOpen(true)}
                        />
                      </AddButtonContainer> : <Button
                        onlyIcon
                        iconLeft={IconAdd}
                        className='buttonBlack'
                        size='m'
                        form='round'
                        onClick={() => setIsModalOpen(true)}
                        style={{ position: 'relative', top: '32px' }}
                      />}
                    </Margin>
                  </Row>
                </Margin>
              )}
            </>
          )}
        </Row>
      </Margin>}
      <Row justify='flex-start' align='center'>
        <Margin mr={24} style={{ width: isMobile ? 'calc(50% - 12px)' : 'unset' }}>
          <Button
            label='Отменить'
            size={isMobile ? 's' : 'm'}
            view='secondary'
            width={isMobile ? 'full' : 'default'}
            form='round'
            onClick={onCancel}
          />
        </Margin>
        <Button
          label='Сохранить'
          size={isMobile ? 's' : 'm'}
          view='primary'
          form='round'
          className='buttonBlack'
          disabled={!currentPickupPoint || pickupPointError}
          onClick={onSave}
          style={{ width: isMobile ? 'calc(50% - 12px)' : 'unset' }}
        />
      </Row>
      <AddressFormModal
        isModalOpen={isModalOpen}
        formData={formData}
        closeModal={handleCloseModal}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default ChangeAddress;