import React, { FC, useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Grid, GridItem } from '@consta/uikit/Grid';
import { Button } from '@consta/uikit/Button';
import { Card } from '@consta/uikit/Card';
import { Text } from '@consta/uikit/Text';
import { TextField } from '@consta/uikit/TextField';
import { Layout } from '@consta/uikit/Layout';
import ReCAPTCHA from 'react-google-recaptcha';
import { LoginContainer, LoginFormWrapper, LoginFormWallpaper, CaptchaWrapper } from 'pages/login/styled';
import { useLoginMutation } from 'features/login/api/login.generated';
import logo from 'assets/logo.png';
import loginImg from 'assets/login-img.png';
import Margin from 'shared/ui/components/margin';
import { emailRegex } from 'shared/constants';
import { setAuthToken } from 'shared/api/auth';
import DesktopOnly from 'shared/ui/components/desktopOnly';
import 'shared/ui/captcha.css';
 
const LoginPage: FC = () => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);

  const captchaRef = useRef<ReCAPTCHA>(null);

  const [login, {data, error}] = useLoginMutation();

  const onLogin = async () => {
    const token = await captchaRef.current?.executeAsync();

    if (email && password && !emailError && !passwordError) {
      await login({
        variables: {
          input: {
            email,
            password,
            captchaCode: token ?? undefined,
          },
        },
      })
    }
  }

  const handleEmailChange = (value: string) => {
    setEmail(value);
    setEmailError(false);
  };

  const handlePasswordChange = (value: string) => {
    setPassword(value);
    setPasswordError(false);
  };

  const handleEmailBlur = () => {
    if (!emailRegex.test(email)) {
      setEmailError(true);
    }
  };

  const handlePasswordBlur = () => {
    if (password.length < 8) {
      setPasswordError(true);
    }
  };

  const navigate = useNavigate(); 

  useEffect(() => {
    if (data?.login?.accessToken && !error) {
      // TODO: refresh token instead of empty string
      setAuthToken(data?.login?.accessToken, '', data?.login?.expiresIn);
      navigate('/');
    }
  }, [data, error]);

  const isMobile = window.innerWidth < 640;
  const isTablet = window.innerWidth < 1100 && window.innerWidth >= 640;
  const smallScreen = isMobile || isTablet;

  return (
    <>
      <LoginContainer>
        <Grid
          cols={1}
          breakpoints={{
            m: {
              cols: 2,
              colGap: 'm',
            },
          }}
        >
          <GridItem>
            <Card verticalSpace='l' horizontalSpace='l' shadow={false}>
              <LoginFormWrapper>
                <img src={logo} alt='logo' />
                <Margin mt={isMobile ? 28 : 36} mb={12}>
                  <Text size={isMobile ? 'xl' : '3xl'} view='primary' align='center'>
                    Вход в личный кабинет LTL Cargo!
                  </Text>
                </Margin>
                <Margin mb={isMobile ? 32 : 48}>
                  <Text size={isMobile ? 'xs' : 's'} view='primary' align='center'>
                    Введите свой email и пароль, указанный при регистрации
                  </Text>
                </Margin>
                <Margin mb={24}>
                  <TextField
                    label='Ваш email'
                    type='text'
                    placeholder='example@gmail.com'
                    required
                    form='round'
                    width='full'
                    size={smallScreen ? 's' : 'm'}
                    value={email}
                    onChange={({ value }) => handleEmailChange(value || '')}
                    onBlur={handleEmailBlur}
                    status={emailError ? 'alert' : undefined}
                    caption={emailError ? 'Неверный email' : undefined}
                  />
                </Margin>
                <Margin mb={32}>
                  <TextField
                    label='Пароль'
                    type='password'
                    placeholder='Введите пароль'
                    required
                    form='round'
                    size={smallScreen ? 's' : 'm'}
                    width='full'
                    value={password}
                    onChange={({ value }) => handlePasswordChange(value || '')}
                    onBlur={handlePasswordBlur}
                    status={passwordError ? 'alert' : undefined}
                    caption={passwordError ? 'Неверный пароль' : undefined}
                  />
                </Margin>
                <Margin mb={20}>
                  <Button
                    label='Войти в личный кабинет'
                    form='round'
                    width='full'
                    disabled={!email || !password || emailError || passwordError}
                    onClick={onLogin}
                  />
                </Margin>
                <Margin mb={isMobile ? 60 : 74}>
                  <Link to='/forget-password'>
                    <Text size='xs' view='link' align='center'>
                      Забыли пароль?
                    </Text>
                  </Link>
                </Margin>
                <Layout direction='row'>
                  <Text size='xs' view='primary' align='center'>
                    Еще нет аккаунта?&nbsp;
                  </Text>
                  <Link to='/register'>
                    <Text view='link' size='xs'>
                      Зарегистрироваться
                    </Text>
                  </Link>
                </Layout>
              </LoginFormWrapper>
            </Card>
          </GridItem>
          <GridItem>
            <DesktopOnly breakpoint={1024}>
              <Card>
                <LoginFormWallpaper>
                  <img src={loginImg} alt='logoImg' />
                </LoginFormWallpaper>
              </Card>
            </DesktopOnly>
          </GridItem>
        </Grid>
      </LoginContainer>
      <CaptchaWrapper>
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_CAPTCHA_TOKEN as string}
          ref={captchaRef}
          size='invisible'
        />
      </CaptchaWrapper>
    </>
  );
};

export default LoginPage;
