import React, { FC } from 'react';
import { Text } from '@consta/uikit/Text';
import { Button } from '@consta/uikit/Button';
import { IconAdd } from '@consta/uikit/IconAdd';
import { Grid, GridItem } from '@consta/uikit/Grid';
import { useNavigate } from 'react-router-dom';
import { useUser } from 'entities/user';
import { usePackagesSummaryQuery } from 'features/main/api/packagesSummary.generated';
import InfoCard from 'features/main/infoCard';
import StockAddresses from 'features/main/stockAddresses';
import Margin from 'shared/ui/components/margin';
import Row from 'shared/ui/components/row';

const MainPage: FC = () => {
  const { user } = useUser();
  const { data } = usePackagesSummaryQuery();

  const navigate = useNavigate();

  const isMobile = window.innerWidth < 640;
  const isTablet = window.innerWidth < 1100 && window.innerWidth >= 640;
  const smallScreen = isMobile || isTablet;

  return (
    <>
      <Margin mb={smallScreen ? isMobile ? 24 : 28 : 32}>
        <Row justify='space-between' align='center'>
          <Text size={smallScreen ? isMobile ? '2xl' : '3xl' : '4xl'} view='primary' weight='medium'>
            Здравствуйте{user?.name ? `, ${user.name.split(' ').shift()}` : ''}
          </Text>
          {!isMobile && <Button
            className='buttonBlack'
            size='s'
            form='round'
            label='Добавить посылку'
            iconRight={IconAdd}
            onClick={() => navigate('/add-package')}
          />}
        </Row>
      </Margin>
      {data?.packagesSummary && (
        <Grid
          cols={1}
          colGap='s'
          rowGap='s'
          breakpoints={{
            m: {
              cols: 4,
              colGap: 'xl',
              rowGap: 'xl',
            },
            s: {
              cols: 2,
              colGap: 'xl',
              rowGap: 'xl',
            },
          }}
        >
          {data.packagesSummary.map((item, idx) => (
            <GridItem key={item.status}>
              <InfoCard data={item} active={idx === 0} />
            </GridItem>
          ))}
        </Grid>
      )}
      <Margin mt={40}>
        <StockAddresses />
      </Margin>
    </>
  );
};

export default MainPage;