import React, { FC, useRef, useState } from 'react';
import { User } from '@consta/uikit/User';
import { ContextMenu } from '@consta/uikit/ContextMenu';
import { useUser } from 'entities/user';

interface dataFormProps {
  withArrow: boolean;
  onRedirect: () => void;
  onLogout: () => void;
}

interface ContextMenuItem {
  title: string;
  callback: () => void;
}

const UserBlock: FC<dataFormProps> = ({
  withArrow,
  onRedirect,
  onLogout,
}: dataFormProps) => {
  const { user } = useUser();
  const [showUserMenu, setShowUserMenu] = useState(false);
  const ref = useRef(null);

  const redirect = () => {
    setShowUserMenu(false);
    onRedirect();
  };

  const menu: ContextMenuItem[] = [
    {
      title: 'Профиль',
      callback: redirect,
    },
    {
      title: 'Выйти',
      callback: onLogout,
    },
  ];

  if (!user) {
    return (<></>);
  }

  return (
    <>
      <div
        role='presentation'
        ref={ref}
        onClick={() => setShowUserMenu(!showUserMenu)}
      >
        <User
          name={user?.name || ''}
          info={`Ваш id: ${user?.id}`}
          withArrow={withArrow}
        />
      </div>
      <ContextMenu
        direction='downCenter'
        isOpen={showUserMenu}
        items={menu}
        anchorRef={ref}
        getItemOnClick={(item: ContextMenuItem) => item.callback}
        onClickOutside={() => setShowUserMenu(false)}
        getItemLabel={(item: ContextMenuItem) => item.title}
      />
    </>
  );
};

export default UserBlock;
