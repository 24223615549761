import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import Header from 'features/header';
import Footer from 'features/footer';
import NotificationsList from 'features/notificationsList';
import { PageContainer, ContentContainer } from './styled';

const Layout: FC = () => {
  const items = [
    {
      label: 'Главная',
      href: '/dashboard',
      active: location?.pathname === '/dashboard',
      order: 0,
    },
    {
      label: 'Посылки',
      href: '/packages',
      active: location?.pathname === '/packages',
      order: 2,
    },
    {
      label: 'Мои адреса',
      href: '/addresses',
      active: location?.pathname === '/addresses',
      order: 4,
    },
    {
      label: 'Инструкция',
      href: '/instructions',
      active: location?.pathname === '/instructions',
      order: 1,
    },
    {
      label: 'Добавить посылку',
      href: '/add-package',
      active: location?.pathname === '/add-package',
      order: 3,
    },
    {
      label: 'Выкуп товара',
      href: '/repurchase',
      active: location?.pathname === '/repurchase',
      order: 5,
    },
  ];

  return (
    <PageContainer>
      <Header items={items} NotificationsList={NotificationsList} />
      <ContentContainer>
        <Outlet />
      </ContentContainer>
      <Footer items={items} />
    </PageContainer>
  );
};

export default Layout;
