import React, { FC, useState, useEffect, useMemo } from 'react';
import { Button } from '@consta/uikit/Button';
import { TextField } from '@consta/uikit/TextField';
import { IconTrash } from '@consta/uikit/IconTrash';
import { Grid, GridItem } from '@consta/uikit/Grid';
import { Maybe } from 'types';
import Margin from 'shared/ui/components/margin';
import { urlRegex } from 'shared/constants';
import { RemoveButtonContainer } from './styled';

export interface RepurchaseData {
  id: number;
  data: RepurchaseItemType | null;
}

export interface RepurchaseItemType {
  itemName: string;
  itemLink: string;
  colorAndSize: string;
  cost: number;
  count: number;
}

export interface RepurchaseError {
  id: number;
  error: boolean;
}

interface IProps {
  onChange: (data: RepurchaseData, error: RepurchaseError) => void;
  packageId: number;
  isLast: boolean;
  onRemove: (id: number) => void;
}

const RepurchaseItem: FC<IProps> = ({
  onChange,
  packageId,
  isLast,
  onRemove,
}: IProps) => {
  const [itemName, setItemName] = useState('');
  const [itemNameError, setItemNameError] = useState(false);
  const [itemLink, setItemLink] = useState('');
  const [itemLinkError, setItemLinkError] = useState(false);
  const [colorAndSize, setColorAndSize] = useState('');
  const [colorAndSizeError, setColorAndSizeError] = useState(false);
  const [count, setCount] = useState<Maybe<number> | undefined>();
  const [countError, setCountError] = useState(false);
  const [costInput, setCostInput] = useState<string>('');
  const [cost, setCost] = useState<Maybe<number> | undefined>();
  const [costError, setCostError] = useState(false);

  const isTablet = window.innerWidth <= 768 && window.innerWidth >= 500;
  const isMobile = window.innerWidth < 500;
  const smallScreen = isMobile || isTablet;

  const isCorrect = useMemo(() => {
    return (
      itemName &&
      itemLink &&
      count &&
      cost &&
      colorAndSize &&
      !itemNameError &&
      !itemLinkError &&
      !colorAndSizeError &&
      !costError &&
      !countError
    );
  }, [
    itemName,
    itemLink,
    count,
    cost,
    colorAndSize,
    itemNameError,
    itemLinkError,
    colorAndSizeError,
    costError,
    countError,
  ]);

  useEffect(() => {
    const data = isCorrect
      ? {
          itemName,
          itemLink,
          colorAndSize,
          cost: cost!,
          count: count!,
        }
      : null;
    onChange({ id: packageId, data }, { id: packageId, error: !isCorrect });
  }, [isCorrect, itemName, itemLink, colorAndSize, cost, count]);

  const handleItemNameChange = (value: string) => {
    const cleanValue = value.replace(/[^A-Za-z_ ]/gi, '');
    setItemName(cleanValue);
    setItemNameError(false);
  };

  const handleItemNameBlur = () => {
    if (!itemName) {
      setItemNameError(true);
    }
  };

  const handleItemLinkChange = (value: string) => {
    setItemLink(value);
    setItemLinkError(false);
  };

  const handleItemLinkBlur = () => {
    if (!urlRegex.test(itemLink)) {
      setItemLinkError(true);
    }
  };

  const handleCountChange = (value: number | undefined) => {
    setCount(value);
    setCountError(false);
  };

  const handleCountBlur = () => {
    if (!count || count <= 0) {
      setCountError(true);
    }
  };

  const handleCostChange = (value: string) => {
    setCostInput(value);
    setCostError(false);
  };

  const handleCostBlur = () => {
    if (!costInput || !parseFloat(costInput) || parseFloat(costInput) <= 0) {
      setCostError(true);
    } else {
      setCost(parseFloat(costInput));
      setCostInput(`${parseFloat(costInput)}`);
    }
  };

  const handleColorAndSizeChange = (value: string) => {
    setColorAndSize(value);
    setColorAndSizeError(false);
  };

  const handleColorAndSizeBlur = () => {
    if (!colorAndSize) {
      setColorAndSizeError(true);
    }
  };

  return (
    <>
      <Margin mb={isLast ? 32 : 60}>
        <Grid
          cols={1}
          colGap='xl'
          rowGap='xl'
          style={{
            paddingBottom: isLast ? '0' : '60px',
            borderBottom: isLast
              ? 'none'
              : '1px solid var(--color-control-bg-border-default)',
          }}
          breakpoints={{
            m: {
              cols: 3,
              colGap: 'xl',
            },
            s: {
              cols: 2,
              colGap: 'xl',
            },
          }}
        >
          <GridItem col={isMobile ? 2 : 1} order={0}>
            <TextField
              label='Название товара на английском'
              type='text'
              placeholder='New Balance'
              required
              form='round'
              width='full'
              size={isMobile ? 's' : 'm'}
              value={itemName}
              onChange={({ value }) => handleItemNameChange(value || '')}
              onBlur={handleItemNameBlur}
              status={itemNameError ? 'alert' : undefined}
              caption={itemNameError ? 'Поле не заполнено' : undefined}
            />
          </GridItem>
          <GridItem col={2} order={isTablet ? 1 : 0}>
            <TextField
              label='Ссылка на товар'
              type='text'
              placeholder='Скопируйте ссылку из интернет-магазина и вставьте в это поле'
              required
              form='round'
              width='full'
              size={isMobile ? 's' : 'm'}
              value={itemLink}
              onChange={({ value }) => handleItemLinkChange(value || '')}
              onBlur={handleItemLinkBlur}
              status={itemLinkError ? 'alert' : undefined}
              caption={itemLinkError ? 'Поле не заполнено' : undefined}
            />
          </GridItem>
          <GridItem col={isMobile ? 2 : 1} order={1}>
            <TextField
              label='Цвет и размер'
              type='text'
              placeholder='Черный цвет, размер 42'
              required
              form='round'
              width='full'
              size={isMobile ? 's' : 'm'}
              value={colorAndSize}
              onChange={({ value }) => handleColorAndSizeChange(value || '')}
              onBlur={handleColorAndSizeBlur}
              status={colorAndSizeError ? 'alert' : undefined}
              caption={colorAndSizeError ? 'Поле не заполнено' : undefined}
            />
          </GridItem>
          <GridItem col={isMobile ? 2 : 1} order={smallScreen ? 0 : 1}>
            <TextField
              label='Количество в шт.'
              type='number'
              incrementButtons
              placeholder='199'
              required
              form='round'
              width='full'
              size={isMobile ? 's' : 'm'}
              value={`${count}`}
              onChange={({ value }) =>
                handleCountChange(value ? parseInt(value) : undefined)
              }
              onBlur={handleCountBlur}
              status={countError ? 'alert' : undefined}
              caption={countError ? 'Поле не заполнено' : undefined}
            />
          </GridItem>
          <GridItem col={isMobile ? 2 : 1} order={1}>
            <TextField
              label='Стоимость за 1 шт в $'
              type='number'
              incrementButtons={false}
              placeholder='199'
              required
              form='round'
              width='full'
              size={isMobile ? 's' : 'm'}
              value={costInput}
              leftSide={'$'}
              onChange={({ value }) => handleCostChange(value || '')}
              onBlur={handleCostBlur}
              status={costError ? 'alert' : undefined}
              caption={
                costError
                  ? costInput
                    ? 'Неверный формат поля'
                    : 'Поле не заполнено'
                  : undefined
              }
            />
          </GridItem>
        </Grid>
        {packageId !== 0 && (
          <Margin mt={12}>
            <RemoveButtonContainer>
              <Button
                label='Удалить товар'
                size='s'
                view='clear'
                iconLeft={IconTrash}
                onClick={() => onRemove(packageId)}
              />
            </RemoveButtonContainer>
          </Margin>
        )}
      </Margin>
    </>
  );
};

export default RepurchaseItem;
