import React, { FC, useState } from 'react';
import { Button } from '@consta/uikit/Button';
import { IconAdd } from '@consta/uikit/IconAdd';
import RepurchaseItem, {
  RepurchaseData,
  RepurchaseError,
  RepurchaseItemType,
} from 'entities/repurchase/repurchase-item';
import Margin from 'shared/ui/components/margin';
import Row from 'shared/ui/components/row';
import { AddButtonContainer } from './styled';

interface IProps {
  onBack: () => void;
  onFinish: (data: RepurchaseItemType[]) => void;
}

const RepurchaseForm: FC<IProps> = ({ onBack, onFinish }: IProps) => {
  const [counter, setCounter] = useState(1);
  const [info, setInfo] = useState<RepurchaseData[]>([{ id: 0, data: null }]);
  const [errors, setErrors] = useState<RepurchaseError[]>([
    { id: 0, error: false },
  ]);

  const isMobile = window.innerWidth < 500;

  const onChange = (data: RepurchaseData, error: RepurchaseError) => {
    setInfo(
      info.map((item) => {
        if (item.id === data.id) {
          return data;
        }
        return item;
      }),
    );
    setErrors(
      errors.map((item) => {
        if (item.id === error.id) {
          return error;
        }
        return item;
      }),
    );
  };

  const onAdd = () => {
    setInfo([...info, { id: counter, data: null }]);
    setErrors([...errors, { id: counter, error: false }]);
    setCounter(counter + 1);
  };

  const onRemove = (id: number) => {
    setInfo(info.filter((item) => item.id !== id));
    setErrors(errors.filter((item) => item.id !== id));
  };

  const onClick = () => {
    if (
      !info.find((item) => item.data === null) &&
      !errors.find((item) => item.error === true)
    ) {
      onFinish(info.map((item) => item.data) as RepurchaseItemType[]);
    }
  };

  return (
    <>
      {info.map((item, idx) => (
        <RepurchaseItem
          key={item.id}
          packageId={item.id}
          onChange={(data: RepurchaseData, error: RepurchaseError) =>
            onChange(data, error)
          }
          isLast={idx === info.length - 1}
          onRemove={(id: number) => onRemove(id)}
        />
      ))}
      <Margin mb={isMobile ? 20 : 60}>
        <AddButtonContainer>
          <Button
            label='Добавить еще один товар'
            size='s'
            view='clear'
            iconRight={IconAdd}
            onClick={onAdd}
          />
        </AddButtonContainer>
      </Margin>
      <Row
        justify='flex-start'
        align='center'
        direction={isMobile ? 'column-reverse' : ''}
      >
        <Margin
          mr={isMobile ? 0 : 24}
          style={{ width: 'unset' }}
          mt={isMobile ? 24 : 0}
        >
          <Button
            label='Назад'
            size='m'
            view='secondary'
            form='round'
            onClick={onBack}
          />
        </Margin>
        <Button
          label='Отправить заявку на выкуп'
          size='m'
          view='primary'
          form='round'
          className='buttonBlack'
          disabled={
            !!info.find((item) => item.data === null) ||
            !!errors.find((item) => item.error === true)
          }
          onClick={onClick}
        />
      </Row>
    </>
  );
};

export default RepurchaseForm;
