import React, { FC, useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Grid, GridItem } from '@consta/uikit/Grid';
import { Button } from '@consta/uikit/Button';
import { Card } from '@consta/uikit/Card';
import { Text } from '@consta/uikit/Text';
import { TextField } from '@consta/uikit/TextField';
import { Layout } from '@consta/uikit/Layout';
import { Checkbox } from '@consta/uikit/Checkbox';
import ReCAPTCHA from 'react-google-recaptcha';
import { LoginContainer, LoginFormWrapper, LoginFormWallpaper, CaptchaWrapper } from 'pages/login/styled';
import Margin from 'shared/ui/components/margin';
import { emailRegex } from 'shared/constants';
import { setAuthToken } from 'shared/api/auth';
import DesktopOnly from 'shared/ui/components/desktopOnly';
import logo from '../../assets/logo.png';
import registerImg from '../../assets/register-img.png';
import { useRegisterMutation } from './api/register.generated';
import 'shared/ui/captcha.css';
 
const RegisterPage: FC = () => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [agreement, setAgreement] = useState(false);

  const captchaRef = useRef<ReCAPTCHA>(null);

  const [register, {data, error}] = useRegisterMutation();

  const onRegister = async () => {
    const token = await captchaRef.current?.executeAsync();

    if (email && password && confirmPassword && !emailError && !passwordError && !confirmPasswordError && agreement) {
      await register({
        variables: {
          input: {
            email,
            password,
            captchaCode: token ?? undefined,
          },
        },
      })
    }
  }

  const handleEmailChange = (value: string) => {
    setEmail(value);
    setEmailError(false);
  };

  const handlePasswordChange = (value: string) => {
    setPassword(value);
    setPasswordError(false);
  };

  const handleEmailBlur = () => {
    if (!emailRegex.test(email)) {
      setEmailError(true);
    }
  };

  const handlePasswordBlur = () => {
    if (password.length < 8) {
      setPasswordError(true);
    }
  };

  const handleConfirmPasswordChange = (value: string) => {
    setConfirmPassword(value);
    setConfirmPasswordError(false);
  };

  const handleConfirmPasswordBlur = () => {
    if (confirmPassword.length < 8 || confirmPassword !== password) {
      setConfirmPasswordError(true);
    }
  };

  const navigate = useNavigate(); 

  useEffect(() => {
    if (data?.register?.accessToken && !error) {
      // TODO: refresh token instead of empty string
      setAuthToken(data?.register?.accessToken, '', data?.register?.expiresIn);
      navigate('/detailed-information');
    }
  }, [data, error]);

  const isMobile = window.innerWidth < 640;
  const isTablet = window.innerWidth < 1100 && window.innerWidth >= 640;
  const smallScreen = isMobile || isTablet;

  return (
    <>
      <LoginContainer>
        <Grid cols={1}
          breakpoints={{
            m: {
              cols: 2,
              colGap: 'm',
            },
          }}
        >
          <GridItem>
            <Card verticalSpace='l' horizontalSpace='l' shadow={false}>
              <LoginFormWrapper>
                <img src={logo} alt='logo' />
                <Margin mt={isMobile ? 28 : 36} mb={12}>
                  <Text size={isMobile ? 'xl' : '3xl'} view='primary' align='center'>
                    Добро пожаловать в LTL Cargo!
                  </Text>
                </Margin>
                <Margin mb={isMobile ? 32 : 48}>
                  <Text size={isMobile ? 'xs' : 's'} view='primary' align='center'>
                    Зарегистрируйтесь с помощью своего почтового адреса
                  </Text>
                </Margin>
                <Margin mb={24}>
                  <TextField
                    label='Ваш email'
                    type='text'
                    placeholder='example@gmail.com'
                    required
                    form='round'
                    width='full'
                    size={smallScreen ? 's' : 'm'}
                    value={email}
                    onChange={({ value }) => handleEmailChange(value || '')}
                    onBlur={handleEmailBlur}
                    status={emailError ? 'alert' : undefined}
                    caption={emailError ? 'Неверный email' : undefined}
                  />
                </Margin>
                <Margin mb={32}>
                  <Grid
                    cols={1}
                    rowGap='l'
                    breakpoints={{
                      s: {
                        cols: 2,
                        colGap: 's',
                      },
                    }}
                  >
                    <GridItem>
                      <TextField
                        label='Придумайте пароль'
                        type='password'
                        placeholder='Введите пароль'
                        required
                        form='round'
                        width='full'
                        size={smallScreen ? 's' : 'm'}
                        value={password}
                        onChange={({ value }) => handlePasswordChange(value || '')}
                        onBlur={handlePasswordBlur}
                        status={passwordError ? 'alert' : undefined}
                        caption={passwordError ? 'Неверный пароль' : undefined}
                      />
                    </GridItem>
                    <GridItem>
                      <TextField
                        label='Повторите пароль'
                        type='password'
                        placeholder='Введите пароль еще раз'
                        required
                        form='round'
                        width='full'
                        size={smallScreen ? 's' : 'm'}
                        value={confirmPassword}
                        onChange={({ value }) => handleConfirmPasswordChange(value || '')}
                        onBlur={handleConfirmPasswordBlur}
                        status={confirmPasswordError ? 'alert' : undefined}
                        caption={confirmPasswordError
                          ? confirmPassword ? 'Пароли не совпадают' : 'Неверный пароль'
                          : undefined}
                      />
                    </GridItem>
                  </Grid>
                </Margin>
                <Margin mb={36}>
                  <Checkbox
                    checked={agreement}
                    onChange={
                      (object: {e: React.ChangeEvent<HTMLInputElement>, checked: boolean}): void => {
                        setAgreement(object.checked);
                      }
                    }
                    size='s'
                    label='Я согласен(-на) с правилами пользования сервисом'
                  />
                </Margin>
                <Margin mb={20}>
                  <Button
                    label='Зарегистрироваться'
                    form='round'
                    width='full'
                    disabled={!email || !password || !confirmPassword || emailError || passwordError || confirmPasswordError || !agreement}
                    onClick={onRegister}
                  />
                </Margin>
                <Layout direction='row'>
                  <Text size='xs' view='primary' align='center'>
                    Уже есть аккаунт?&nbsp;
                  </Text>
                  <Link to='/login'>
                    <Text view='link' size='xs'>
                      Войти
                    </Text>
                  </Link>
                </Layout>
              </LoginFormWrapper>
            </Card>
          </GridItem>
          <GridItem>
            <DesktopOnly breakpoint={1024}>
              <Card>
                <LoginFormWallpaper>
                  <img src={registerImg} alt='logoImg' />
                </LoginFormWallpaper>
              </Card>
            </DesktopOnly>
          </GridItem>
        </Grid>
      </LoginContainer>
      <CaptchaWrapper>
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_CAPTCHA_TOKEN as string}
          ref={captchaRef}
          size='invisible'
        />
      </CaptchaWrapper>
    </>
  );
};

export default RegisterPage;
