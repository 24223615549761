import React, { FC, useState, useEffect, useMemo } from 'react';
import { Select } from '@consta/uikit/Select';
import { Button } from '@consta/uikit/Button';
import { TextField } from '@consta/uikit/TextField';
import { IconTrash } from '@consta/uikit/IconTrash';
import { Grid, GridItem } from '@consta/uikit/Grid';
import { Maybe, CargoType } from 'types';
import Margin from 'shared/ui/components/margin';
import Row from 'shared/ui/components/row';
import { cargoTypeItems } from 'shared/constants/cargoTypes.constants';
import { PackageDataWithId, PackageErrorWithId } from '../package-information/types';
import { RemoveButtonContainer } from './styled';

interface IProps {
  onChange: (data: PackageDataWithId, error: PackageErrorWithId) => void,
  packageId: number,
  isLast: boolean,
  onRemove: (id: number) => void,
};

const PackageInformationItem: FC<IProps> = ({ onChange, packageId, isLast, onRemove }: IProps) => {
  const [track, setTrack] = useState('');
  const [trackError, setTrackError] = useState(false);
  const [description, setDescription] = useState('');
  const [descriptionError, setDescriptionError] = useState(false);
  const [count, setCount] = useState<Maybe<number> | undefined>();
  const [countError, setCountError] = useState(false);
  const [costInput, setCostInput] = useState<string>('');
  const [cost, setCost] = useState<Maybe<number> | undefined>();
  const [costError, setCostError] = useState(false);
  const [cargoType, setCargoType] = useState<CargoType | null>(null);
  const [cargoTypeError, setCargoTypeError] = useState(false);

  const isCorrect = useMemo(() => {
    return track
      && description
      && count
      && cost
      && cargoType
      && !trackError
      && !descriptionError
      && !cargoTypeError
      && !costError
      && !countError;
  }, [
    track, description, count, cost, cargoType, trackError,
    descriptionError, cargoTypeError, costError, countError,
  ])

  useEffect(() => {
    const data = isCorrect
      ?  {
          track,
          description,
          cargoType,
          cost: cost!,
          count: count!,
          insured: false,
        }
      : null;
      onChange({ id: packageId, data }, { id: packageId, error: !isCorrect });
  }, [isCorrect, track, description, cargoType, cost, count]);

  const handleTrackChange = (value: string) => {
    setTrack(value);
    setTrackError(false);
  };

  const handleTrackBlur = () => {
    if (!track || track.length < 3 || track.length >= 32) {
      setTrackError(true);
    }
  };

  const handleDescriptionChange = (value: string) => {
    setDescription(value);
    setDescriptionError(false);
  };

  const handleDescriptionBlur = () => {
    if (!description || description.length > 256) {
      setDescriptionError(true);
    }
  };

  const handleCountChange = (value: number | undefined) => {
    setCount(value);
    setCountError(false);
  };

  const handleCountBlur = () => {
    if (!count || count <= 0 || count > 100) {
      setCountError(true);
    }
  };

  const handleCostChange = (value: string) => {
    setCostInput(value);
    setCostError(false);
  };

  const handleCostBlur = () => {
    if (
      !costInput
      || !parseFloat(costInput)
      || parseFloat(costInput) <= 0
      || parseFloat(costInput) > 10000
    ) {
      setCostError(true);
    } else {
      setCost(parseFloat(costInput));
      setCostInput(`${parseFloat(costInput)}`);
    }
  };

  const handleCargoTypeChange = (value: CargoType | null) => {
    if (!value) return;

    setCargoType(value);
    setCargoTypeError(false);
  };

  const handleCargoTypeBlur = () => {
    if (!cargoType) {
      setCargoTypeError(true);
    }
  };

  const isMobile = window.innerWidth < 640;
  const isTablet = window.innerWidth < 1100 && window.innerWidth >= 640;
  const smallScreen = isMobile || isTablet;

  return (
    <>
      <Margin mb={isLast
        ? isMobile ? 20 : 32
        : smallScreen ? 40 : 60
      }>
        <Grid
          cols={1}
          colGap='l'
          rowGap='l'
          style={{
            paddingBottom: isLast ? '0' : smallScreen ? '40px' : '60px',
            borderBottom: isLast ? 'none' : '1px solid var(--color-control-bg-border-default)',
          }}
          breakpoints={{
            m: {
              cols: 3,
              colGap: 'xl',
              rowGap: 'xl',
            },
            s: {
              cols: 2,
              colGap: 'xl',
              rowGap: 'xl',
            },
          }}
        >
          <GridItem order={isTablet ? -1 : 0}>
            <TextField
              label='Трек-номер'
              type='text'
              placeholder='1928481756158159285'
              required
              form='round'
              width='full'
              size={isMobile ? 's' : 'm'}
              value={track}
              onChange={({ value }) => handleTrackChange(value || '')}
              onBlur={handleTrackBlur}
              status={trackError ? 'alert' : undefined}
              caption={trackError ? track ? 'Длина поля от 3 до 32 символов' : 'Поле не заполнено' : undefined}
            />
          </GridItem>
          <GridItem col={isMobile ? 1 : 2} order={isTablet ? 1 : 0}>
            <TextField
              label='Описание'
              type='text'
              placeholder='Кроссовки New Balance и футболка'
              required
              form='round'
              width='full'
              size={isMobile ? 's' : 'm'}
              value={description}
              onChange={({ value }) => handleDescriptionChange(value || '')}
              onBlur={handleDescriptionBlur}
              status={descriptionError ? 'alert' : undefined}
              caption={descriptionError
                ? description
                  ? 'Максимальное количество символов - 256'
                  : 'Поле не заполнено'
                : undefined}
            />
          </GridItem>
          <GridItem order={isTablet ? -1 : 0}>
            <TextField
              label='Количество товаров'
              type='number'
              incrementButtons={false}
              placeholder='100'
              required
              form='round'
              width='full'
              size={isMobile ? 's' : 'm'}
              value={`${count}`}
              onChange={({ value }) => handleCountChange(value ? parseInt(value) : undefined)}
              onBlur={handleCountBlur}
              status={countError ? 'alert' : undefined}
              caption={countError ? count ? 'Максимальное количество - 100' : 'Поле не заполнено' : undefined}
            />
          </GridItem>
          <GridItem order={isTablet ? 1 : 0}>
            <TextField
              label='Цена в долларах ($)'
              type='number'
              incrementButtons={false}
              placeholder='199'
              required
              form='round'
              width='full'
              size={isMobile ? 's' : 'm'}
              value={costInput}
              onChange={({ value }) => handleCostChange(value || '')}
              onBlur={handleCostBlur}
              status={costError ? 'alert' : undefined}
              caption={costError
                ? costInput
                  ? parseFloat(costInput) > 0
                    ? 'Максимальное значение - 10000$'
                    : 'Поле должно быть положительным'
                  : 'Поле не заполнено'
                : undefined}
            />
          </GridItem>
          <GridItem order={isTablet ? 1 : 0}>
            <Select
              label='Характер груза'
              placeholder='Выберите из списка'
              items={cargoTypeItems}
              form='round'
              size={isMobile ? 's' : 'm'}
              required
              value={cargoType ? cargoTypeItems.find(item => item.value === cargoType) : null}
              getItemLabel={(item) => item.label}
              getItemKey={(item) => item.value}
              onChange={({ value }) => handleCargoTypeChange(value ? value?.value : null)}
              status={cargoTypeError ? 'alert' : undefined}
              caption={cargoTypeError ? 'Поле не заполнено' : undefined}
              onBlur={handleCargoTypeBlur}
            />
          </GridItem>
        </Grid>
        {packageId !== 0 && <Margin mt={isMobile ? 20 : 32}>
          <Row justify='flex-end' align='center'>
            <RemoveButtonContainer>
              <Button
                label='Удалить посылку'
                size='s'
                view='clear'
                onlyIcon={isMobile}
                iconLeft={IconTrash}
                onClick={() => onRemove(packageId)}
              />
            </RemoveButtonContainer>
          </Row>
        </Margin>}
      </Margin>
    </>
  );
};

export default PackageInformationItem;