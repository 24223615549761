import React, { FC, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, GridItem } from '@consta/uikit/Grid';
import { Button } from '@consta/uikit/Button';
import { Card } from '@consta/uikit/Card';
import { Text } from '@consta/uikit/Text';
import { TextField } from '@consta/uikit/TextField';
import { LoginContainer, LoginFormWrapper } from 'pages/login/styled';
import { useResetPasswordMutation } from 'features/reset/api/resetPassword.generated';
import logo from 'assets/logo.png';
import Margin from 'shared/ui/components/margin';
import { setAuthToken } from 'shared/api/auth';
 
const ResetPage: FC = () => {
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);

  const [resetPassword, {data, error, loading}] = useResetPasswordMutation();

  const { id } = useParams();

  const onReset = async () => {
    if (password && !passwordError && confirmPassword && !confirmPasswordError && id) {
      await resetPassword({
        variables: {
          input: {
            password,
            code: id,
          },
        },
      })
    }
  }

  const handlePasswordChange = (value: string) => {
    setPassword(value);
    setPasswordError(false);
  };

  const handlePasswordBlur = () => {
    if (password.length < 8) {
      setPasswordError(true);
    }
  };

  const handleConfirmPasswordChange = (value: string) => {
    setConfirmPassword(value);
    setConfirmPasswordError(false);
  };

  const handleConfirmPasswordBlur = () => {
    if (confirmPassword.length < 8 || confirmPassword !== password) {
      setConfirmPasswordError(true);
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (data?.resetPassword?.accessToken && !error) {
      // TODO: refresh token instead of empty string
      setAuthToken(data?.resetPassword?.accessToken, '', data?.resetPassword?.expiresIn);
      navigate('/');
    }
  }, [data, error]);

  const isMobile = window.innerWidth < 640;
  const isTablet = window.innerWidth < 1100 && window.innerWidth >= 640;
  const smallScreen = isMobile || isTablet;

  return (
    <LoginContainer>
      <Card verticalSpace='l' horizontalSpace='5xl' shadow={false}>
        <LoginFormWrapper>
          <img src={logo} alt='logo' />
          <Margin mt={isMobile ? 28 : 36} mb={12}>
            <Text size={isMobile ? 'xl' : '3xl'} view='primary' align='center'>
              Сброс пароля
            </Text>
          </Margin>
          <Margin mb={isMobile ? 32 : 48}>
            <Text size='s' view='primary' align='center'>
              Придумайте новый пароль
            </Text>
          </Margin>
          <Margin mb={32}>
            <Grid
              cols={1}
              rowGap='l'
              breakpoints={{
                s: {
                  cols: 2,
                  colGap: 's',
                },
              }}
            >
              <GridItem>
                <TextField
                  label='Придумайте пароль'
                  type='password'
                  placeholder='Введите пароль'
                  required
                  form='round'
                  width='full'
                  size={smallScreen ? 's' : 'm'}
                  value={password}
                  onChange={({ value }) => handlePasswordChange(value || '')}
                  onBlur={handlePasswordBlur}
                  status={passwordError ? 'alert' : undefined}
                  caption={passwordError ? 'Неверный пароль' : undefined}
                />
              </GridItem>
              <GridItem>
                <TextField
                  label='Повторите пароль'
                  type='password'
                  placeholder='Введите пароль еще раз'
                  required
                  form='round'
                  width='full'
                  size={smallScreen ? 's' : 'm'}
                  value={confirmPassword}
                  onChange={({ value }) => handleConfirmPasswordChange(value || '')}
                  onBlur={handleConfirmPasswordBlur}
                  status={confirmPasswordError ? 'alert' : undefined}
                  caption={confirmPasswordError
                    ? confirmPassword ? 'Пароли не совпадают' : 'Неверный пароль'
                    : undefined}
                />
              </GridItem>
            </Grid>
          </Margin>
          <Button
            label='Сохраните новый пароль'
            form='round'
            width='full'
            loading={loading}
            disabled={!password || !confirmPassword || passwordError || confirmPasswordError}
            onClick={onReset}
          />
        </LoginFormWrapper>
      </Card>
    </LoginContainer>
  );
};

export default ResetPage;
