import React, { useEffect, useState } from 'react';
import { Text } from '@consta/uikit/Text';
import { Modal } from '@consta/uikit/Modal';
import { Button } from '@consta/uikit/Button';
import { Select } from '@consta/uikit/Select';
import { TextField } from '@consta/uikit/TextField';
import { Grid, GridItem } from '@consta/uikit/Grid';
import { countries } from 'entities/countries/utils';
import CountryLabel from 'features/country-label';
import { CitizenshipItem } from 'entities/countries/types';
import { AddressType, UserCountry } from 'types';
import { countriesMap } from 'shared/constants/countries.constants';
import { AddressModalWrapper, AddressesFormModalHeaderWrapper } from './styled';

export type SubmitAddressInput = {
  country: UserCountry;
  city: string;
  street: string;
  building: string;
  apartment: string;
  id?: number;
};

type AddressModalPropos = {
  isModalOpen: boolean;
  closeModal: () => void;
  onSubmit: ({
    id,
    country,
    city,
    street,
    building,
    apartment,
  }: SubmitAddressInput) => void;
  formData: AddressType | null;
};

type CountryItem = Pick<CitizenshipItem, 'value' | 'label'>;

const AddressFormModal = ({
  isModalOpen,
  formData,
  closeModal,
  onSubmit,
}: AddressModalPropos) => {
  const [country, setCountry] = useState<CountryItem | null>();
  const [city, setCity] = useState('');
  const [street, setStreet] = useState('');
  const [building, setBuilding] = useState('');
  const [apartment, setApartment] = useState('');

  const [countryError, setCountryError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [streetError, setStreetError] = useState(false);
  const [buildingError, setBuildingError] = useState(false);
  const [apartmentError, setApartmentError] = useState(false);
  const isTablet = window.innerWidth <= 768 && window.innerWidth >= 500;
  const isMobile = window.innerWidth < 500;

  useEffect(() => {
    if (formData?.id) {
      setCountry({
        label: countriesMap[formData?.country],
        value: formData?.country,
      });
      setCity(formData?.address?.city);
      setStreet(formData?.address?.street);
      setBuilding(formData?.address?.home);
      setApartment(formData?.address?.flat);
    }
  }, [formData]);

  const handleCountryChange = (item: CountryItem | null) => {
    if (!item?.value) return;

    setCountry(item);
    setCountryError(false);
  };

  const handleCityChange = (value: string) => {
    setCity(value);
    setCityError(false);
  };

  const handleStreetChange = (value: string) => {
    setStreet(value);
    setStreetError(false);
  };

  const handleBuildingChange = (value: string) => {
    setBuilding(value);
    setBuildingError(false);
  };

  const handleApartmentChange = (value: string) => {
    setApartment(value);
    setApartmentError(false);
  };

  const handleCountryBlur = () => {
    if (!country?.value) {
      setCountryError(true);
    }
  };

  const handleCityBlur = () => {
    if (!city) {
      setCityError(true);
    }
  };

  const handleStreetBlur = () => {
    if (!street) {
      setStreetError(true);
    }
  };

  const handleBuildingBlur = () => {
    if (!building) {
      setBuildingError(true);
    }
  };

  const handleApartmentBlur = () => {
    if (!apartment) {
      setApartmentError(true);
    }
  };

  const clearForm = () => {
    setCountry(null);
    setCity('');
    setStreet('');
    setBuilding('');
    setApartment('');
  };

  const closeModalAndClearForm = () => {
    clearForm();
    closeModal();
  };

  const onSave = () => {
    if (!country?.value) {
      setCountryError(true);
      return;
    }

    onSubmit({
      country: country.value,
      city,
      street,
      building,
      apartment,
      id: formData?.id,
    });
    closeModalAndClearForm();
  };

  const isEdit = !!formData?.id;
  const formTitle = isEdit
    ? 'Редактирование адреса получения посылок'
    : 'Добавление адреса получения посылок';
  const saveButtonTitle = isEdit ? 'Обновить адрес' : 'Сохранить новый адрес';

  const submitDisabled =
    !country?.value || !city || !street || !building || !apartment;

  return (
    <Modal
      isOpen={isModalOpen}
      onClickOutside={() => closeModalAndClearForm()}
      onEsc={() => closeModalAndClearForm()}
    >
      <AddressModalWrapper>
        <AddressesFormModalHeaderWrapper>
          <Text size='3xl' view='primary'>
            {formTitle}
          </Text>
        </AddressesFormModalHeaderWrapper>
        <Grid
          cols={1}
          rowGap='xl'
          colGap='xl'
          breakpoints={{
            l: {
              cols: 8,
              colGap: 'm',
            },
            m: {
              cols: 8,
              colGap: 'm',
            },
            s: {
              cols: 8,
              colGap: 'm',
            },
          }}
        >
          <GridItem col={isMobile ? undefined : isTablet ? 4 : 3}>
            <Select
              label='Страна'
              placeholder='Выберите из списка'
              items={countries}
              form='round'
              required
              value={country}
              getItemLabel={(item: CountryItem) => item.label || ''}
              getItemKey={(item: CountryItem) => item.value}
              status={countryError ? 'alert' : undefined}
              caption={countryError ? 'Поле не заполнено' : undefined}
              onBlur={handleCountryBlur}
              onChange={({ value }) => handleCountryChange(value)}
              renderItem={({
                item,
                active,
                hovered,
                onClick,
                onMouseEnter,
              }) => (
                <CountryLabel
                  item={item}
                  active={active}
                  hovered={hovered}
                  onClick={onClick}
                  onMouseEnter={onMouseEnter}
                />
              )}
            />
          </GridItem>
          <GridItem col={isMobile ? undefined : isTablet ? 4 : 5}>
            <TextField
              label='Город'
              type='text'
              placeholder='Алматы'
              required
              form='round'
              width='full'
              value={city}
              onChange={({ value }) => handleCityChange(value || '')}
              onBlur={handleCityBlur}
              status={cityError ? 'alert' : undefined}
              caption={cityError ? 'Поле не заполнено' : undefined}
            />
          </GridItem>
          <GridItem col={isMobile ? undefined : isTablet ? 8 : 4}>
            <TextField
              label='Улица'
              type='text'
              placeholder='Абая'
              required
              form='round'
              width='full'
              value={street}
              onChange={({ value }) => handleStreetChange(value || '')}
              onBlur={handleStreetBlur}
              status={streetError ? 'alert' : undefined}
              caption={streetError ? 'Поле не заполнено' : undefined}
            />
          </GridItem>
          <GridItem col={isMobile ? undefined : isTablet ? 4 : 2}>
            <TextField
              label='Номер дома'
              type='text'
              placeholder='10'
              required
              form='round'
              width='full'
              value={building}
              onChange={({ value }) => handleBuildingChange(value || '')}
              onBlur={handleBuildingBlur}
              status={buildingError ? 'alert' : undefined}
              caption={buildingError ? 'Поле не заполнено' : undefined}
            />
          </GridItem>
          <GridItem col={isMobile ? undefined : isTablet ? 4 : 2}>
            <TextField
              label='Номер квартиры'
              type='number'
              placeholder='19'
              required
              form='round'
              width='full'
              incrementButtons={false}
              value={apartment}
              onChange={({ value }) => handleApartmentChange(value || '')}
              onBlur={handleApartmentBlur}
              status={apartmentError ? 'alert' : undefined}
              caption={apartmentError ? 'Поле не заполнено' : undefined}
            />
          </GridItem>
        </Grid>
        <Button
          size='l'
          view='primary'
          form='round'
          width='default'
          className='buttonBlack'
          label={saveButtonTitle}
          disabled={submitDisabled}
          onClick={onSave}
        />
      </AddressModalWrapper>
    </Modal>
  );
};

export default AddressFormModal;
