import React, { FC, useState } from 'react';
import { Grid, GridItem } from '@consta/uikit/Grid';
import { Checkbox } from '@consta/uikit/Checkbox';
import { Button } from '@consta/uikit/Button';
import { Text } from '@consta/uikit/Text';
import { PackageData } from 'pages/add-package/types';
import InfoField from 'features/detailed-package/info-field';
import Margin from 'shared/ui/components/margin';
import Row from 'shared/ui/components/row';
import { AddButtonContainer } from '../package-information/styled';

interface IProps {
  packages: PackageData[],
  onBack: () => void,
  onFinish: (packages: PackageData[]) => void,
};

const Insuranse: FC<IProps> = ({ packages, onBack, onFinish }: IProps) => {
  const [currentPackages, setCurrentPackages] = useState(packages);

  const isMobile = window.innerWidth < 640;
  const isTablet = window.innerWidth < 1100 && window.innerWidth >= 640;

  return (
    <>
      <Margin mb={20}>
        {!isMobile ? <Grid cols={12} colGap='s'>
          <GridItem col={3}>
            <Text size={isTablet ? 'm' : 'l'} view='primary'>Трек номер</Text>
          </GridItem>
          <GridItem col={4}>
            <Text size={isTablet ? 'm' : 'l'} view='primary'>Описание</Text>
          </GridItem>
          <GridItem col={5}>
            <Row justify='flex-start' align='center'>
              <Checkbox
                checked={!currentPackages.filter(item => !item?.insured).length}
                onChange={(object: {e: React.ChangeEvent<HTMLInputElement>, checked: boolean}): void => {
                  setCurrentPackages(currentPackages.map(item => ({ ...item, insured: object.checked })))
                }}
                size='m'
              />
              <Margin ml={15}>
                <Text align='left' size={isTablet ? 'm' : 'l'} view='primary'>Застраховать</Text>
              </Margin>
            </Row>
          </GridItem>
        </Grid> : <Row justify='flex-end' align='center' style={{
          paddingBottom: '23px',
          borderBottom: '1px solid var(--color-control-bg-border-default)',
        }}>
          <AddButtonContainer>
            <Button
              label={!currentPackages.filter(elem => !elem?.insured).length
                ? 'Отменить страховку для всех посылок'
                : 'Застраховать все посылки'}
              size='s'
              view='clear'
              onClick={() => setCurrentPackages(currentPackages.map(elem => ({ ...elem, insured: !!currentPackages.filter(item => !item?.insured).length })))}
            />
          </AddButtonContainer>
        </Row>}
      </Margin>
      {currentPackages.map(item => (
        <Margin mb={isMobile ? 24 : 16} key={item.track}>
          {!isMobile ? <Grid cols={12} colGap='s'>
            <GridItem col={3}>
              <Text view='secondary'>{item.track}</Text>
            </GridItem>
            <GridItem col={4}>
              <Text view='secondary'>{item.description}</Text>
            </GridItem>
            <GridItem col={5}>
              <Checkbox
                checked={item?.insured}
                onChange={(object: {e: React.ChangeEvent<HTMLInputElement>, checked: boolean}): void => {
                  setCurrentPackages(currentPackages.map(el => {
                    if (el.track === item.track) {
                      return { ...el, insured: object.checked };
                    }
                    return el;
                  }))
                }}
                size='s'
              />
            </GridItem>
          </Grid> : <Grid
            cols={2}
            colGap='m'
            rowGap='m'
            style={{
              paddingBottom: '23px',
              borderBottom: '1px solid var(--color-control-bg-border-default)',
            }}
          >
            <GridItem>
              <InfoField label='Трек-номер' value={item.track || ''} />
            </GridItem>
            <GridItem>
              <InfoField
                label='Описание'
                value={item.description
                  ? item.description.length > 25 ? item.description.slice(0, 25) + '...' : item.description
                  : ''
                }
              />
            </GridItem>
            <GridItem>
              <Text size='2xs' view='secondary' transform='uppercase'>
                Застраховать
              </Text>
            </GridItem>
            <GridItem>
              <Checkbox
                checked={item?.insured}
                onChange={(object: {e: React.ChangeEvent<HTMLInputElement>, checked: boolean}): void => {
                  setCurrentPackages(currentPackages.map(el => {
                    if (el.track === item.track) {
                      return { ...el, insured: object.checked };
                    }
                    return el;
                  }))
                }}
                size='s'
              />
            </GridItem>
          </Grid>}
        </Margin>
      ))}
      <Margin mt={isMobile ? 28 : 44}>
        <Row justify={isMobile ? 'space-between' : 'flex-start'} align='center'>
          <Margin mr={24} style={{ width: 'unset' }}>
            <Button
              label='Назад'
              size={isMobile ? 's' : 'm'}
              view='secondary'
              form='round'
              onClick={onBack}
            />
          </Margin>
          <Button
            label='Далее'
            size={isMobile ? 's' : 'm'}
            view='primary'
            form='round'
            className='buttonBlack'
            onClick={() => onFinish(currentPackages)}
          />
        </Row>
      </Margin>
    </>
  );
};

export default Insuranse;