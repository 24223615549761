import React, { FC, useRef, useState } from 'react';
import { Button } from '@consta/uikit/Button';
import { IconMeatball } from '@consta/icons/IconMeatball';
import { ContextMenu } from '@consta/uikit/ContextMenu';
import { NotificationType } from 'types';
import { useNotifications } from 'entities/user-notifications/hooks';
import { useDeleteNotificationMutation } from '../../../entities/user-notifications/api/deleteNotification.generated';
import { useReadNotificationMutation } from '../../../entities/user-notifications/api/readNotification.generated';
import { CardWrapper } from './styled';

interface IProps {
  notification: NotificationType;
  readAllNotifications: () => void;
}

type ItemAction = {
  label: string;
  event: () => void;
  accent?: 'alert' | 'warning' | 'success';
};

const NotificationCard: FC<IProps> = ({
  notification,
  readAllNotifications,
}: IProps) => {
  const { id, title, text, read, createdAt } = notification;
  const { refetch } = useNotifications();
  const [deleteNotification] = useDeleteNotificationMutation();
  const [readNotification] = useReadNotificationMutation();
  const ref = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isMobile = window.innerWidth < 640;

  const getItemOnClick = (item: ItemAction) => item.event;

  const getItemActions = (): ItemAction[] => {
    const actions: ItemAction[] = [
      {
        label: 'Удалить из списка',
        event: handleDeleteNotification,
        accent: 'alert',
      },
    ];

    const readAction: ItemAction = {
      label: 'Отметить как прочитанное',
      event: handleReadNotification,
      accent: 'warning',
    };

    const readAllAction: ItemAction = {
      label: 'Отметить все как прочитанные',
      event: readAllNotifications,
      accent: 'success',
    };

    if (isMobile) actions.unshift(readAllAction);
    if (!read) actions.unshift(readAction);
    return actions;
  };

  const handleDeleteNotification = () => {
    deleteNotification({
      variables: { id },
    }).then(updateNotifications);
  };

  const handleReadNotification = () => {
    readNotification({
      variables: { id },
    }).then(updateNotifications);
  };

  const updateNotifications = () => {
    refetch();
    toggleMenu();
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const getFormattedDate = () => {
    const date = new Date(createdAt);
    const now = new Date();

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hour = date.getHours();
    const minutes = date.getMinutes();
    const diffMs = Date.now() - +date;
    const diffSec = Math.round(diffMs / 1000);
    const diffMin = Math.round(diffSec / 60);
    const diffHour = Math.round(diffMin / 60);

    // форматирование
    const yearStr = year.toString().slice(-2);
    const monthStr = month < 10 ? '0' + month : month;
    const dayStr = day < 10 ? '0' + day : day;
    const hourStr = hour < 10 ? '0' + hour : hour;
    const minutesStr = minutes < 10 ? '0' + minutes : minutes;

    if (diffMin < 1) {
      return 'только что';
    } else if (diffHour < 1) {
      return `${diffMin} мин назад`;
    } else if (
      year === now.getFullYear() &&
      month === now.getMonth() + 1 &&
      day === now.getDate()
    ) {
      return `${hourStr}:${minutesStr}`;
    } else {
      return `${dayStr}.${monthStr}.${yearStr} ${hourStr}:${minutesStr}`;
    }
  };

  return (
    <CardWrapper key={id}>
      <div className={`main_info${!read ? ' read' : ''}`}>
        <p>{title}</p>
        <Button
          ref={ref}
          iconLeft={IconMeatball}
          view='clear'
          size='xs'
          onClick={toggleMenu}
        />
        <ContextMenu
          className='notificationsContextMenu'
          direction='downStartRight'
          isOpen={isOpen}
          anchorRef={ref}
          style={{ zIndex: 1 }}
          size={isMobile ? 'xs' : 'm'}
          getItemStatus={(item) => item?.accent}
          getItemOnClick={getItemOnClick}
          onClickOutside={() => setIsOpen(false)}
          items={getItemActions()}
        />
      </div>
      <p className={`text${!read ? ' read' : ''}`}>{text}</p>
      <p className='date'>{getFormattedDate()}</p>
    </CardWrapper>
  );
};

export default NotificationCard;
