import React from 'react';
import { Text } from '@consta/uikit/Text';
import { Card } from '@consta/uikit/Card';
import { Button } from '@consta/uikit/Button';
import { IconAdd } from '@consta/uikit/IconAdd';

type EmptyAddressProps = {
  addNewAddress: () => void;
};

const EmptyAddresses = ({ addNewAddress }: EmptyAddressProps) => {
  return (
    <Card verticalSpace='l' horizontalSpace='l' shadow={false}>
      <Text size='3xl' weight='medium'>
        Вы еще не добавили ни одного адреса
      </Text>
      <Button
        className='buttonBlack'
        size='s'
        form='round'
        label='Добавить новый адрес'
        iconRight={IconAdd}
        onClick={addNewAddress}
      />
    </Card>
  );
};

export default EmptyAddresses;
