import React, { FC, useState, useEffect } from 'react';
import { Button } from '@consta/uikit/Button';
import { Grid, GridItem } from '@consta/uikit/Grid';
import { DeliveryRateType, StockType } from 'types';
import Margin from 'shared/ui/components/margin';
import Row from 'shared/ui/components/row';
import { useDeliveryRatesListQuery } from '../api/deliveryRatesList.generated';
import DeliveryRateItem from '../delivery-rate-item';
import { DeliveryRateWithCheckbox } from './types';

interface IProps {
  stock: StockType,
  onBack: () => void,
  onFinish: (deliveryRate: DeliveryRateType) => void,
};

const DeliveryRate: FC<IProps> = ({ stock, onBack, onFinish }: IProps) => {
  const [rates, setRates] = useState<DeliveryRateWithCheckbox[]>([]);

  const { data } = useDeliveryRatesListQuery({
    variables: {
      input: {
        stockId: stock.id,
      },
    },
  });

  useEffect(() => {
    if (data?.deliveryRatesList) {
      setRates(data.deliveryRatesList.map((item, idx) => ({ ...item, checked: idx === 0 })));
    }
  }, [data]);

  const onClick = () => {
    const index = rates.findIndex(item => item.checked);

    if (index !== -1 && data?.deliveryRatesList[index]) {
      onFinish(data.deliveryRatesList[index]);
    }
  };

  const isMobile = window.innerWidth < 640;
  const isTablet = window.innerWidth < 1100 && window.innerWidth >= 640;
  const smallScreen = isMobile || isTablet;

  return (
    <>
      {rates.length && <Margin mb={smallScreen ? isMobile ? 28 : 32 : 60}>
        <Grid
          cols={1}
          rowGap='s'
          colGap='xl'
          breakpoints={{
            m: {
              cols: 3,
            },
            s: {
              cols: 2,
            },
          }}
        >
          {rates.map(item => (
            <GridItem key={item.id}>
              <DeliveryRateItem
                item={item}
                stock={stock}
                onCheck={(id) => setRates(rates.map(elem => ({ ...elem, checked: elem.id === id })))}
              />
            </GridItem>
          ))}
        </Grid>
      </Margin>}
      <Row justify={isMobile ? 'space-between' : 'flex-start'} align='center'>
        <Margin mr={24} style={{ width: 'unset' }}>
          <Button
            label='Назад'
            size={isMobile ? 's' : 'm'}
            view='secondary'
            form='round'
            onClick={onBack}
          />
        </Margin>
        <Button
          label='Далее'
          size={isMobile ? 's' : 'm'}
          view='primary'
          form='round'
          className='buttonBlack'
          onClick={onClick}
        />
      </Row>
    </>
  );
};

export default DeliveryRate;