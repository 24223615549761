import React, { FC, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@consta/uikit/Button';
import { Card } from '@consta/uikit/Card';
import { Text } from '@consta/uikit/Text';
import { TextField } from '@consta/uikit/TextField';
import { Layout } from '@consta/uikit/Layout';
import { LoginContainer, LoginFormWrapper } from 'pages/login/styled';
import { useSendResetCodeMutation } from 'features/forget-password/api/sendResetCode.generated';
import logo from 'assets/logo.png';
import Margin from 'shared/ui/components/margin';
import { emailRegex } from 'shared/constants';
 
const ForgetPasswordPage: FC = () => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [seconds, setSeconds] = useState(0);

  const [sendResetCode, {data, error, loading}] = useSendResetCodeMutation();

  const onSend = async () => {
    if (email && !emailError) {
      await sendResetCode({
        variables: {
          input: {
            email,
          },
        },
      })
    }
  };

  const sendAgain = async () => {
    setSeconds(60);
    onSend();
  };

  useEffect(() => {
    const id = setInterval(() => {
      if (seconds !== 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);
    return () => clearInterval(id);
  }, [seconds]);

  const handleEmailChange = (value: string) => {
    setEmail(value);
    setEmailError(false);
  };

  const handleEmailBlur = () => {
    if (!emailRegex.test(email)) {
      setEmailError(true);
    }
  };

  useEffect(() => {
    if (data?.sendResetCode && !error) {
      console.log(data?.sendResetCode);
      setSuccess(true);
      setSeconds(60);
    }
  }, [data, error]);

  const isMobile = window.innerWidth < 640;
  const isTablet = window.innerWidth < 1100 && window.innerWidth >= 640;
  const smallScreen = isMobile || isTablet;

  return (
    <LoginContainer>
      <Card verticalSpace='l' horizontalSpace='5xl' shadow={false}>
        <LoginFormWrapper>
          <img src={logo} alt='logo' />
          <Margin mt={isMobile ? 28 : 36} mb={12}>
            <Text size={isMobile ? 'xl' : '3xl'} view='primary' align='center'>
              {success ? 'Проверьте свою почту' : 'Сброс пароля'}
            </Text>
          </Margin>
          {success ? (
            <>
              <Margin mb={100}>
                <Text size={isMobile ? 'xs' : 's'} view='primary' align='center'>
                  Мы выслали письмо на адрес {email}.<br />
                  Перейдите по ссылке внутри письма, чтобы установить новый пароль.
                </Text>
              </Margin>
              {seconds === 0 ? (
                <Layout direction='row'>
                  <Text size='xs' view='primary' align='center'>
                    Не пришло письмо на почту?&nbsp;
                  </Text>
                  <Text
                    view='link'
                    size='xs'
                    onClick={sendAgain}
                    cursor='pointer'
                  >
                    Отправить еще раз
                  </Text>
                </Layout>
              ) : (
                <Text size='xs' view='primary' align='center'>
                    Отправить письмо повторно можно через {seconds} сек
                  </Text> 
              )}
            </>
          ) : (
            <>
              <Margin mb={isMobile ? 32 : 48}>
                <Text size={isMobile ? 'xs' : 's'} view='primary' align='center'>
                  Введите свой email и мы вышлем письмо для сброса пароля
                </Text>
              </Margin>
              <Margin mb={32}>
                <TextField
                  label='Ваш email'
                  type='text'
                  placeholder='example@gmail.com'
                  required
                  form='round'
                  width='full'
                  size={smallScreen ? 's' : 'm'}
                  value={email}
                  onChange={({ value }) => handleEmailChange(value || '')}
                  onBlur={handleEmailBlur}
                  status={emailError ? 'alert' : undefined}
                  caption={emailError ? 'Неверный email' : undefined}
                />
              </Margin>
              <Margin mb={isMobile ? 60 : 74}>
                <Button
                  label='Сбросить пароль'
                  form='round'
                  width='full'
                  loading={loading}
                  disabled={!email || emailError}
                  onClick={onSend}
                />
              </Margin>
              <Layout direction='row'>
                <Text size='xs' view='primary' align='center'>
                  Еще нет аккаунта?&nbsp;
                </Text>
                <Link to='/register'>
                  <Text view='link' size='xs'>
                    Зарегистрироваться
                  </Text>
                </Link>
              </Layout>
            </>
          )}
        </LoginFormWrapper>
      </Card>
    </LoginContainer>
  );
};

export default ForgetPasswordPage;
