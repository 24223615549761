import { Grid, GridItem } from '@consta/uikit/Grid';
import { Link } from 'react-router-dom';
import { Text } from '@consta/uikit/Text';

export type NavigationListItem = {
  label: string;
  href: string;
  active: boolean;
  order: number;
};

export const PublicOffer = () => {
  return (
    <Grid cols={1} rowGap={'xs'}>
      <GridItem>
        <Link to={'#'}>
          <Text view='secondary' size='xs'>
            Политика конфиденциальности
          </Text>
        </Link>
      </GridItem>
      <GridItem>
        <Link to={'https://ltl-cargo.com/oferta'} target='_blank'>
          <Text view='secondary' size='xs'>
            Публичная оферта
          </Text>
        </Link>
      </GridItem>
    </Grid>
  );
};

export default PublicOffer;
