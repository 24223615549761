import React, { FC, useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Text } from '@consta/uikit/Text';
import { Button } from '@consta/uikit/Button';
import { Badge } from '@consta/uikit/Badge';
import { IconBackward } from '@consta/uikit/IconBackward';
import { Card } from '@consta/uikit/Card';
import { Grid, GridItem } from '@consta/uikit/Grid';
import { useGetPackageQuery } from 'features/detailed-package/api/getPackage.generated';
import { useUpdatePackageMutation } from 'features/detailed-package/api/updatePackage.generated';
import { useDeletePackageMutation } from 'features/detailed-package/api/deletePackage.generated';
import InfoField from 'features/detailed-package/info-field';
import { DeliveryStatus, PackageType, PickupPointType, StockCode } from 'types';
import ChangeAddress from 'features/detailed-package/change-address';
import Margin from 'shared/ui/components/margin';
import { statusesBadges, statusesLabels } from 'shared/constants/statuses.constants';
import { countriesMap } from 'shared/constants/countries.constants';
import { DetailedPackageContainer, EditButtonContainer, RemoveButtonContainer } from './styled';

const DetailedPackage: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const location = useLocation();

  const [packageInfo, setPackageInfo] = useState<PackageType | undefined>();

  const [editMode, setEditMode] = useState(false);

  const [updatePackage, { data: dataUpdate, error: errorUpdate }] = useUpdatePackageMutation();
  const [deletePackage] = useDeletePackageMutation();

  const { data, refetch } = useGetPackageQuery({
    skip: !id,
    variables: {
      id: +id!,
    },
  });

  useEffect(() => {
    if (data?.getPackage) {
      setPackageInfo(data.getPackage);
    }
  }, [data]);

  const saveEdit = (pickupPoint: PickupPointType, addressId?: number) => {
    updatePackage({
      variables: {
        id: +id!,
        input: {
          pickupPointId: pickupPoint.id,
          deliveryAddressId: addressId,
        },
      },
    }).then(() => setEditMode(false));
  };

  useEffect(() => {
    if (dataUpdate?.updatePackage && !errorUpdate) {
      const refetchPackage = async () => await refetch();
      refetchPackage();
    }
  }, [dataUpdate, errorUpdate]);

  const goBack = () => {
    if (location.key !== 'default') {
      navigate(-1);
    } else {
      navigate('/packages');
    }
  };

  const onRemove = () => {
    deletePackage({
      variables: {
        id: id ? parseInt(id) : 0,
      },
    });

    goBack();
  };

  const isMobile = window.innerWidth < 640;
  const isTablet = window.innerWidth < 1100 && window.innerWidth >= 640;
  const smallScreen = isMobile || isTablet;

  return (
    <>
      <Button
        view='clear'
        size='s'
        label='Назад к посылкам'
        iconLeft={IconBackward}
        onClick={goBack}
      />
      <Margin mb={smallScreen ? isMobile ? 40 : 28 : 32}>
        <Text size={smallScreen ? isMobile ? '2xl' : '3xl' : '4xl'} view='primary' weight='medium'>
          Детальная информация о посылке
        </Text>
      </Margin>
      {packageInfo && (
        <DetailedPackageContainer>
          <Card
            verticalSpace={smallScreen ? isMobile ? 'l' : 'xl' : '2xl'}
            horizontalSpace={smallScreen ? isMobile ? 's' : 'l' : 'xl'}
            shadow={false}
          >
            <Grid
              cols={2}
              rowGap='xl'
              breakpoints={{
                m: {
                  cols: 4,
                  rowGap: '2xl',
                },
              }}
            >
              <GridItem>
                <InfoField label='Трек-номер' value={packageInfo.track} />
              </GridItem>
              <GridItem>
                <InfoField
                  label='Дата добавления'
                  value={dayjs(packageInfo.createdAt).format('DD.MM.YYYY')} />
              </GridItem>
              <GridItem col={smallScreen ? 1 : 2}>
                <InfoField label='Склад' value={packageInfo.deliveryRate.stock.name || ''} />
              </GridItem>
              <GridItem>
                <Margin mb={12}>
                  <Text size='l' view='primary'>
                    Статус
                  </Text>
                </Margin>
                <Badge
                  status={statusesBadges[packageInfo.status]}
                  label={statusesLabels[packageInfo.status]}
                />
              </GridItem>
              <GridItem col={smallScreen ? 2 : 3}>
                <InfoField label='Описание посылки' value={packageInfo.description || ''} />
              </GridItem>
            </Grid>
            <Margin mt={smallScreen ? isMobile ? 32 : 36 : 40}>
              <Grid
                cols={1}
                rowGap='xl'
                breakpoints={{
                  s: {
                    cols: 2,
                  },
                  m: {
                    cols: 4,
                  },
                }}
              >
                {!editMode ? (
                  <>
                    <GridItem>
                      <InfoField
                        label='Способ доставки'
                        value={packageInfo?.pickupPoint
                          ? packageInfo.pickupPoint?.name
                          : packageInfo?.deliveryAddress
                            ? 'Доставка до двери'
                            : ''
                          }
                      />
                    </GridItem>
                    <GridItem col={smallScreen ? 1 : 3}>
                      {packageInfo.deliveryAddress?.country && (
                        <InfoField
                          label='Адрес доставки'
                          value={`${countriesMap[packageInfo.deliveryAddress.country]}, г. ${packageInfo.deliveryAddress?.address.city}, ул.${packageInfo.deliveryAddress?.address.street} ${packageInfo.deliveryAddress?.address.home}, кв. ${packageInfo.deliveryAddress?.address.flat}`}
                        />
                      )}
                      {packageInfo.status !== DeliveryStatus.Done && (
                        <Margin mt={packageInfo.deliveryAddress?.country ? 10 : 0}>
                          <EditButtonContainer>
                            <Button
                              label='Изменить адрес доставки'
                              size='s'
                              view='clear'
                              onClick={() => setEditMode(true)}
                            />
                          </EditButtonContainer>
                        </Margin>
                      )}
                    </GridItem>
                  </>
                ) : (
                  <GridItem col={smallScreen ? isMobile ? 1 : 2 : 3}>
                    <ChangeAddress
                      stock={packageInfo.deliveryRate.stock}
                      onCancel={() => setEditMode(false)}
                      onFinish={(pickupPoint: PickupPointType, addressId?: number) => saveEdit(pickupPoint, addressId)}
                    />
                  </GridItem>
                )}
              </Grid>
            </Margin>
            <Margin mt={smallScreen ? isMobile ? 32 : 36 : 40}>
              {packageInfo.deliveryRate.stock.code !== StockCode.Kr ? (
                <Grid
                  cols={2}
                  rowGap='xl'
                  breakpoints={{
                    m: {
                      cols: 4,
                      rowGap: '2xl',
                    },
                  }}
                >
                  <GridItem>
                    <InfoField label='Тариф' value={packageInfo.deliveryRate.name} />
                  </GridItem>
                  <GridItem>
                    <InfoField label='Стоимость посылки' value={`$ ${packageInfo.packageCost || ''}`} />
                  </GridItem>
                  <GridItem>
                    <InfoField label='Стоимость страховки' value={`$ ${packageInfo.insuranceCost || ''}`} />
                  </GridItem>
                  <GridItem col={isMobile ? 2 : 1}>
                    {packageInfo.status === DeliveryStatus.Created && (
                      <RemoveButtonContainer>
                        <Button
                          label='Удалить посылку'
                          size='s'
                          view='clear'
                          onClick={onRemove}
                        />
                      </RemoveButtonContainer>
                    )}
                  </GridItem>
                  {packageInfo.status !== DeliveryStatus.Created && (
                    <>
                      <GridItem>
                        <InfoField label='Вес посылки' value={`${packageInfo.weight || ''} кг`} />
                      </GridItem>
                      <GridItem col={smallScreen ? 2 : 3}>
                        <InfoField
                          label='Общая стоимость доставки'
                          value={`$ ${packageInfo.deliveryRate.cost || ''}`}
                        />
                        {packageInfo.status === DeliveryStatus.ReadyForDelivery && (
                          <Margin mt={smallScreen ? 12 : 16}>
                            <Text size={isMobile ? 'xs' : 's'} view='primary'>
                              Скоро с вами свяжется наш менеджер для уточнения деталей доставки и оплаты
                            </Text>
                          </Margin>
                        )}
                      </GridItem>
                    </>
                  )}
                </Grid>
              ) : (
                <Grid
                  cols={2}
                  rowGap='xl'
                  breakpoints={{
                    m: {
                      cols: 4,
                      rowGap: '2xl',
                    },
                  }}
                >
                  <GridItem>
                    <InfoField label='Тариф' value={packageInfo.deliveryRate.name} />
                  </GridItem>
                  <GridItem>
                    <InfoField label='Количество мест' value={`${packageInfo?.packageCount || ''}`} />
                  </GridItem>
                  <GridItem>
                    <InfoField label='Вес груза' value={`${packageInfo?.weight || ''} кг`} />
                  </GridItem>
                  <GridItem col={isMobile ? 2 : 1}>
                    {packageInfo.status === DeliveryStatus.Created && (
                      <RemoveButtonContainer>
                        <Button
                          label='Удалить посылку'
                          size='s'
                          view='clear'
                          onClick={onRemove}
                        />
                      </RemoveButtonContainer>
                    )}
                  </GridItem>
                </Grid>
              )}
            </Margin>
          </Card>
        </DetailedPackageContainer>
      )}
    </>
  );
};

export default DetailedPackage;