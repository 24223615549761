import React, { FC, useState, useEffect } from 'react';
import { Text } from '@consta/uikit/Text';
import { Card } from '@consta/uikit/Card';
import { Button } from '@consta/uikit/Button';
import { ProgressStepBar } from '@consta/uikit/ProgressStepBar';
import { DefaultItem } from '@consta/uikit/__internal__/src/components/ProgressStepBar/helpers';
import { useNavigate, useLocation } from 'react-router-dom';
import Stock from 'features/add-package/stock';
import PackageInformation from 'features/add-package/package-information';
import Insuranse from 'features/add-package/insuranse';
import DeliveryRate from 'features/add-package/delivery-rate';
import Delivery from 'features/add-package/delivery';
import {
  CreatePackageInput,
  DeliveryRateType,
  PickupPointType,
  StockCode,
  StockType,
} from 'types';
import { useCreatePackagesMutation } from 'features/add-package/api/createPackages.generated';
import Margin from 'shared/ui/components/margin';
import { StepBarContainer, ContentCard } from './styled';
import { PackageData } from './types';

const AddPackagePage: FC = () => {
  const [activeStep, setActiveStep] = useState(0);

  const [currentStock, setCurrentStock] = useState<StockType | null>(null);
  const [currentDeliveryRate, setCurrentDeliveryRate] =
    useState<DeliveryRateType | null>(null);
  const [packageData, setPackageData] = useState<PackageData[]>([]);

  const [createPackages] = useCreatePackagesMutation();
  const [isFinished, setIsFinished] = useState(false);

  const navigate = useNavigate();

  const steps: DefaultItem[] = [
    {
      label: 'Выбор склада',
      point: 1,
      content: (
        <>
          {(currentStock && (
            <Text size='s' view='secondary'>
              {currentStock?.name}
            </Text>
          )) || null}
        </>
      ),
    },
    {
      label: 'Информация о посылке',
      point: 2,
      content: (
        <>
          {(packageData.length && (
            <Text size='s' view='secondary'>
              Добавлено посылок: {packageData.length} шт
            </Text>
          )) ||
            null}
        </>
      ),
    },
    {
      label: 'Страховка',
      point: 3,
      content: (
        <>
          {(packageData.length && activeStep > 2 && (
            <Text size='s' view='secondary'>
              Застраховано посылок:{' '}
              {packageData.filter((item) => item?.insured).length} шт
            </Text>
          )) ||
            null}
        </>
      ),
    },
    {
      label: 'Тариф',
      point: currentStock?.code === StockCode.Kr ? 3 : 4,
      content: (
        <>
          {(currentDeliveryRate && (
            <Text size='s' view='secondary'>
              Тариф {currentDeliveryRate?.name}
            </Text>
          )) ||
            null}
        </>
      ),
    },
    {
      label: 'Доставка',
      point: currentStock?.code === StockCode.Kr ? 4 : 5,
    },
  ];

  const onFinishStocks = (stock: StockType) => {
    setActiveStep(activeStep + 1);
    setCurrentStock(stock);
    setPackageData([]);
    setShowStepbar(false);
  };

  const onFinishInfo = (data: PackageData[]) => {
    setActiveStep(activeStep + 1);
    setPackageData(data);
    setShowStepbar(false);
  };

  const onFinishInsuranse = (data: PackageData[]) => {
    setActiveStep(activeStep + 1);
    setPackageData(data);
    setShowStepbar(false);
  };

  const onFinishDeliveryRate = (deliveryRate: DeliveryRateType) => {
    setCurrentDeliveryRate(deliveryRate);
    setActiveStep(activeStep + 1);
    setShowStepbar(false);
  };

  const [showStepbar, setShowStepbar] = useState(true);

  const location = useLocation();

  // это какой то ужас, но без этого на 3-4 шаге просто пропадает линия степбара
  useEffect(() => {
    if (!showStepbar) {
      setShowStepbar(true);
    }
  }, [showStepbar]);

  useEffect(() => {
    setShowStepbar(false);
  }, [location, isFinished]);

  const onFinishDelivery = async (
    pickupPoint: PickupPointType,
    addressId?: number,
  ) => {
    const packagesInput: CreatePackageInput[] = packageData.map((item) => ({
      track: item.track,
      description: item.description || undefined,
      packageCost: (currentStock?.code !== StockCode.Kr && item.cost) ? item.cost : undefined,
      weight: (currentStock?.code === StockCode.Kr && item.weight) ? item.weight : undefined,
      packageCount: item.count || undefined,
      insured: (currentStock?.code !== StockCode.Kr && item.insured) ? item.insured : undefined,
      deliveryRateId: currentDeliveryRate!.id,
      pickupPointId: pickupPoint.id || undefined,
      deliveryAddressId: addressId || undefined,
    }));

    await createPackages({
      variables: {
        input: packagesInput,
      },
    }).then(() => setIsFinished(true));
  };

  const onBack = () => {
    setActiveStep(activeStep - 1);
    setShowStepbar(false);
  }

  const isMobile = window.innerWidth < 640;
  const isTablet = window.innerWidth < 1100 && window.innerWidth >= 640;
  const smallScreen = isMobile || isTablet;

  return (
    <>
      <Margin mb={smallScreen ? isMobile ? 16 : 18 : 40}>
        <Text size={smallScreen ? isMobile ? '2xl' : '3xl' : '4xl'} view='primary' weight='medium'>
          Добавление посылки
        </Text>
      </Margin>
      <Margin mb={smallScreen ? 30 : 55}>
        <StepBarContainer>
          {showStepbar && (
            <ProgressStepBar
              steps={currentStock?.code === StockCode.Kr
                ? steps.filter(item => item.label !== 'Страховка')
                : steps
              }
              activeStepIndex={activeStep}
              getItemLabel={(item) => item.label}
              getItemStatus={(item) => {
                if ((item.point as number) <= activeStep || isFinished) {
                  return 'success';
                }
              }}
              getItemLineStatus={(item) => {
                if ((item.point as number) <= activeStep || isFinished) {
                  return 'success';
                }
              }}
            />
          )}
        </StepBarContainer>
      </Margin>
      <ContentCard>
        <Card shadow={false}>
          {activeStep === 0 && !isFinished && (
            <Stock
              caption='Выберите склад, на который будет доставлена ваша посылка'
              onFinish={(stock: StockType) => onFinishStocks(stock)}
            />
          )}
          {activeStep === 1 && !isFinished && (
            <PackageInformation
              stock={currentStock as StockType}
              onBack={onBack}
              onFinish={(data: PackageData[]) => onFinishInfo(data)}
            />
          )}
          {activeStep === 2 && !isFinished && (
            <>
              {currentStock?.code === StockCode.Kr ? (
                <DeliveryRate
                  stock={currentStock as StockType}
                  onBack={onBack}
                  onFinish={(deliveryRate: DeliveryRateType) =>
                    onFinishDeliveryRate(deliveryRate)
                  }
                />
              ) : (
                <Insuranse
                  packages={packageData}
                  onBack={onBack}
                  onFinish={(data: PackageData[]) => onFinishInsuranse(data)}
                />
              )}
            </>
          )}
          {activeStep === 3 && !isFinished && (
            <>
              {currentStock?.code === StockCode.Kr ? (
                <Delivery
                  stock={currentStock as StockType}
                  onBack={onBack}
                  onFinish={(
                    pickupPoint: PickupPointType,
                    addressId?: number,
                  ) => onFinishDelivery(pickupPoint, addressId)}
                />
              ) : (
                <DeliveryRate
                  stock={currentStock as StockType}
                  onBack={onBack}
                  onFinish={(deliveryRate: DeliveryRateType) =>
                    onFinishDeliveryRate(deliveryRate)
                  }
                />
              )}
            </>
          )}
          {activeStep === 4 && !isFinished && (
            <Delivery
              stock={currentStock as StockType}
              onBack={onBack}
              onFinish={(pickupPoint: PickupPointType, addressId?: number) =>
                onFinishDelivery(pickupPoint, addressId)
              }
            />
          )}
          {isFinished && (
            <>
              <Text size='3xl' weight='medium' view='primary'>
                Добавление успешно завершено
              </Text>
              <Margin mt={12} mb={32}>
                <Text size='s' view='secondary'>
                  Вы можете отслеживать свои покупки на странице Посылки
                </Text>
              </Margin>
              <Button
                className='buttonBlack'
                size='s'
                form='round'
                label='Перейти к посылкам'
                onClick={() => navigate('/packages')}
              />
            </>
          )}
        </Card>
      </ContentCard>
    </>
  );
};

export default AddPackagePage;
