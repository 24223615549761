import React, { FC, useState, useRef } from 'react';
import { Text } from '@consta/uikit/Text';
import { Button } from '@consta/uikit/Button';
import { Card } from '@consta/uikit/Card';
import { Radio } from '@consta/uikit/Radio';
import { IconInfo } from '@consta/uikit/IconInfo';
import { Tooltip } from '@consta/uikit/Tooltip';
import { Grid, GridItem } from '@consta/uikit/Grid';
import { StockType } from 'types';
import Margin from 'shared/ui/components/margin';
import Row from 'shared/ui/components/row';
import { DeliveryRateWithCheckbox } from '../delivery-rate/types';
import { DeliveryRateCard, TooltipContent } from './styled';
import './tooltip.css';

interface IProps {
  item: DeliveryRateWithCheckbox,
  stock: StockType,
  onCheck: (id: number) => void,
};

const DeliveryRateItem: FC<IProps> = ({ item, stock, onCheck }: IProps) => {
  const costRef = useRef<HTMLButtonElement>(null);

  const [isOpenCostTooltip, setIsOpenCostTooltip] = useState(false);

  const handleToggleCostTooltip = () => setIsOpenCostTooltip(!isOpenCostTooltip);

  const timeRef = useRef<HTMLButtonElement>(null);

  const [isOpenTimeTooltip, setIsOpenTimeTooltip] = useState(false);

  const handleToggleTimeTooltip = () => setIsOpenTimeTooltip(!isOpenTimeTooltip);

  const isMobile = window.innerWidth < 640;
  const isTablet = window.innerWidth < 1100 && window.innerWidth >= 640;
  const smallScreen = isMobile || isTablet;

  return (
    <DeliveryRateCard>
      <Card shadow={false} verticalSpace='l' horizontalSpace='l'>
        <Margin mb={isMobile ? 11 : 16}>
          <Row justify='space-between' align='center'>
            <Text view='primary' size={smallScreen ? isMobile ? 's' : 'm' : 'l'}>
              Ваш тариф: {item.name}
            </Text>
            <Radio
              view='primary'
              checked={item.checked}
              onChange={() => onCheck(item.id)}
            />
          </Row>
        </Margin>
        <Grid cols={2}>
          <GridItem>
            <Row justify='flex-start' align='center'>
              <Text size={isMobile ? 'xs' : 's'} view='secondary'>Стоимость</Text>
              &nbsp;
              <Button
                view='clear'
                form='round'
                size='xs'
                iconLeft={IconInfo}
                onlyIcon
                ref={costRef}
                onClick={handleToggleCostTooltip}
              />
              {isOpenCostTooltip && (
                <Tooltip
                  size={'l'}
                  direction={'rightDown'}
                  anchorRef={costRef}
                  onClickOutside={handleToggleCostTooltip}
                  className='whiteTooltip'
                >
                  <TooltipContent>
                    {item.description}<br />
                    <Button
                      form='round'
                      label='Закрыть'
                      size='s'
                      onClick={handleToggleCostTooltip}
                    />
                  </TooltipContent>
                </Tooltip>
              )}
            </Row>
            <Text size={isMobile ? 's' : 'm'} view='primary'>
              от ${item.cost} / кг
            </Text>
          </GridItem>
          <GridItem>
            <Row justify='flex-start' align='center'>
              <Text size={isMobile ? 'xs' : 's'} view='secondary'>Срок доставки</Text>
              &nbsp;
              <Button
                view='clear'
                form='round'
                size='xs'
                iconLeft={IconInfo}
                onlyIcon
                ref={timeRef}
                onClick={handleToggleTimeTooltip}
              />
              {isOpenTimeTooltip && (
                <Tooltip
                  size={'l'}
                  direction={'rightDown'}
                  anchorRef={timeRef}
                  onClickOutside={handleToggleTimeTooltip}
                  className='whiteTooltip'
                >
                  <TooltipContent>
                    Срок доставки рассчитывается с момента отправки посылки со склада в {stock.name}&nbsp;
                    и до прибытия посылки в распределительный центр в Алматы. Точный срок доставки до 
                    вашего города может отличаться.<br />
                    <Button
                      form='round'
                      label='Закрыть'
                      size='s'
                      onClick={handleToggleTimeTooltip}
                    />
                  </TooltipContent>
                </Tooltip>
              )}
            </Row>
            <Text size={isMobile ? 's' : 'm'} view='primary'>
              {item.time}
            </Text>
          </GridItem>
        </Grid>
      </Card>
    </DeliveryRateCard>
  );
};

export default DeliveryRateItem;