import React, { FC, useEffect, useState } from 'react';
import { Card } from '@consta/uikit/Card';
import { Text } from '@consta/uikit/Text';
import { Button } from '@consta/uikit/Button';
import OtpInput from 'react-otp-input';
import { useNavigate } from 'react-router-dom';
import { LoginContainer, LoginFormWrapper } from 'pages/login/styled';
import {
  TextButtonWrapper,
  ConfirmationInputsWrapper,
} from 'pages/phone-confirmation/styled';
import { useUser } from 'entities/user';
import { formatPhoneNumber } from 'entities/phone/utils';
import { useSendConfirmationCodeMutation } from 'features/phone-confirmation/api/sendPhoneConfirmation.generated';
import { useConfirmPhoneMutation } from 'features/phone-confirmation/api/confirmPhone.generated';
import Margin from 'shared/ui/components/margin';
import MobileOnly from 'shared/ui/components/mobileOnly';
import logo from '../../assets/logo.png';

const ConfirmPhonePage: FC = () => {
  const { user, loading: userLoading, refetch: refetchMe } = useUser();
  const phone = user?.phone || '';
  const formattedPhoneNumber = formatPhoneNumber(phone);

  const [sendConfirmationCode] = useSendConfirmationCodeMutation();
  const [confirmPhone, { error: confirmationError }] =
    useConfirmPhoneMutation();

  const [seconds, setSeconds] = useState(60);
  const [code, setCode] = useState('');

  const isMobile = window.innerWidth < 640;

  const resend = async () => {
    setSeconds(60);
    sendConfirmationCode();
  };

  const refetchUser = async () => {
    await refetchMe();
  };

  useEffect(() => {
    if (!user?.phone) {
      refetchUser();
    } else {
      sendConfirmationCode();
    }
  }, [user]);

  useEffect(() => {
    const id = setInterval(() => {
      if (seconds !== 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);
    return () => clearInterval(id);
  }, [seconds]);

  const navigate = useNavigate();

  const confirmCode = async () => {
    if (code.length === 4) {
      const result = await confirmPhone({
        variables: {
          code,
        },
      });

      if (result.data?.confirmPhone?.accessToken) {
        await refetchUser();
        navigate('/');
      }
    }
  };

  useEffect(() => {
    if (code.length === 4) {
      confirmCode();
    }
  }, [code]);

  return (
    <LoginContainer>
      <Card verticalSpace='l' horizontalSpace='5xl' shadow={false}>
        <LoginFormWrapper>
          <img src={logo} alt='logo' />
          <Margin mt={36} mb={12}>
            <Text size='3xl' view='primary' align='center'>
              Подтверждение <MobileOnly breakpoint={440}>{'\n'}</MobileOnly>{' '}
              номера телефона
            </Text>
          </Margin>
          <Margin mb={48}>
            {!userLoading ? (
              <Text size={isMobile ? 'xs' : 's'} view='primary' align='center'>
                Мы отправили СМС с кодом на номер
                <MobileOnly breakpoint={440}>{'\n'}</MobileOnly>{' '}
                {formattedPhoneNumber}
              </Text>
            ) : null}
          </Margin>
          <ConfirmationInputsWrapper>
            <OtpInput
              value={code}
              onChange={(c) => setCode(c)}
              numInputs={4}
              renderInput={(props) => <input {...props} />}
              inputStyle={
                confirmationError
                  ? 'TextField-InputContainer_view_default TextField-InputContainer_status_alert'
                  : 'TextField-InputContainer_view_default'
              }
              placeholder='1234'
              inputType='number'
            />
          </ConfirmationInputsWrapper>
          {confirmationError ? (
            <Text size='xs' view='alert'>
              Введен неверный код
            </Text>
          ) : null}
          <Margin mt={32} mb={32} />
          {seconds !== 0 && (
            <Text size='xs'>Отправить код повторно через {seconds} секунд</Text>
          )}
          {seconds === 0 && (
            <>
              <Text size='xs'>Не удалось получить SMS?</Text>
              <TextButtonWrapper>
                <Button
                  size='xs'
                  view='clear'
                  label='Отправить код повторно'
                  onClick={resend}
                />
              </TextButtonWrapper>
            </>
          )}
        </LoginFormWrapper>
      </Card>
    </LoginContainer>
  );
};

export default ConfirmPhonePage;
