import { useRef, useState } from 'react';
import { VerticalMenu } from '@consta/header/VerticalMenu';
import { Button } from '@consta/uikit/Button';
import { IconHamburger } from '@consta/uikit/IconHamburger';
import { IconClose } from '@consta/uikit/IconClose';
import { useNavigate } from 'react-router-dom';
import { Modal } from '@consta/uikit/Modal';
import NavigationMenu, { NavigationMenuItem } from 'entities/ui/navigationMenu';
import UserBlock from 'entities/ui/userBlock';
import { useNotifications } from 'entities/user-notifications/hooks';
import renderNotifyBtn from 'entities/user-notifications';
import Margin from 'shared/ui/components/margin';
import { removeAuthToken } from 'shared/api/auth';
import DesktopOnly from 'shared/ui/components/desktopOnly';
import MobileOnly from 'shared/ui/components/mobileOnly';
import MobileFullScreenMenu from 'shared/ui/components/mobileFullScreenMenu';
import logo from '../../assets/footer-logo.png';
import { ActionsWrapper, CustomHeader } from './styled';

const Header = ({
  items,
  NotificationsList,
}: {
  items: NavigationMenuItem[];
  NotificationsList: React.FC;
}) => {
  const [isNotificationsModalOpen, setIsNotificationsModalOpen] =
    useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const { listNotifications } = useNotifications();

  const mobileMenuItems = [...items, { label: 'Профиль', href: '/profile' }];

  const handleClickOnAnchor = () => {
    setShowMobileMenu(false);
    setIsNotificationsModalOpen(!isNotificationsModalOpen);
  };

  const anchorRef = useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();

  const openProfile = () => {
    navigate('/profile');
  };

  const onLogout = () => {
    removeAuthToken();
    navigate('/login');
  };

  const onAddPackage = () => {
    setShowMobileMenu(false);
    navigate('/add-package');
  };

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  return (
    <CustomHeader
      rowCenter={{
        left: (
          <>
            <img src={logo} alt='logo' />
            <DesktopOnly breakpoint={1280}>
              <NavigationMenu items={items} />
            </DesktopOnly>
          </>
        ),
        center: '',
        right: (
          <ActionsWrapper>
            <DesktopOnly breakpoint={640}>
              <Margin mr={24}>
                <Button
                  className='buttonBlack'
                  size='s'
                  form='round'
                  label='Добавить посылку'
                  onClick={() => navigate('/add-package')}
                />
              </Margin>
            </DesktopOnly>
            <Margin mr={12}>
              {renderNotifyBtn({
                listNotifications,
                anchorRef,
                handleClickOnAnchor,
              })}
              {isNotificationsModalOpen && (
                <Modal
                  className='notificationsModal'
                  isOpen={isNotificationsModalOpen}
                  onClickOutside={() => setIsNotificationsModalOpen(false)}
                  onEsc={() => setIsNotificationsModalOpen(false)}
                >
                  <NotificationsList />
                </Modal>
              )}
            </Margin>
            <DesktopOnly breakpoint={640}>
              <UserBlock
                withArrow={false}
                onLogout={onLogout}
                onRedirect={openProfile}
              />
            </DesktopOnly>
            <MobileOnly breakpoint={1280}>
              <Margin ml={16}>
                <Button
                  label='Меню'
                  view='clear'
                  iconLeft={showMobileMenu ? IconClose : IconHamburger}
                  onlyIcon
                  size='s'
                  onClick={toggleMobileMenu}
                />
              </Margin>
            </MobileOnly>
            {showMobileMenu && (
              <MobileFullScreenMenu zIndex={2}>
                <>
                  <MobileOnly breakpoint={640}>
                    <Margin ml={24} mt={12} mb={12}>
                      <UserBlock
                        withArrow={false}
                        onLogout={onLogout}
                        onRedirect={openProfile}
                      />
                    </Margin>
                  </MobileOnly>
                  <VerticalMenu
                    items={mobileMenuItems}
                    getItemActive={(item) => item.href === location?.pathname}
                    header={
                      <MobileOnly breakpoint={640}>
                        <Button
                          size='s'
                          view='clear'
                          label='Добавить посылку'
                          onClick={onAddPackage}
                        />
                        <div
                          className='
                            che--VerticalMenuLevel-Divider
                            MixSpace MixSpace_mT_s MixSpace_mL_xl MixSpace_mR_xl MixSpace_mB_s'
                        />
                      </MobileOnly>
                    }
                    footer={
                      <MobileOnly breakpoint={640}>
                        <Button
                          size='s'
                          view='clear'
                          label='Выйти'
                          onClick={onLogout}
                        />
                      </MobileOnly>
                    }
                  />
                </>
              </MobileFullScreenMenu>
            )}
          </ActionsWrapper>
        ),
      }}
    />
  );
};

export default Header;
