import { ReactElement } from 'react';
import { IconRing } from '@consta/uikit/IconRing';
import { NotificationType } from 'types';
import { NotificationsBtnWrapper } from './styled';

interface RenderNotifyInput {
  listNotifications: NotificationType[];
  anchorRef: React.RefObject<HTMLButtonElement>;
  handleClickOnAnchor: () => void;
}

const renderNotifyBtn = ({
  listNotifications,
  anchorRef,
  handleClickOnAnchor,
}: RenderNotifyInput): ReactElement => {
  const btnClass = listNotifications?.filter(
    (notify: NotificationType) => !notify.read,
  ).length
    ? undefined
    : 'no_notifications_icon';

  return (
    <NotificationsBtnWrapper>
      <IconRing
        view='link'
        size='m'
        ref={anchorRef}
        onClick={handleClickOnAnchor}
        className={btnClass}
      />
    </NotificationsBtnWrapper>
  );
};

export default renderNotifyBtn;
