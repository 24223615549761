import NavigationList, { NavigationListItem } from 'entities/ui/navigationList';
import { HelpSectionText } from 'entities/ui/helpTextSection';
import PublicOffer from 'entities/ui/publicOffer';
import { Watermark } from 'entities/ui/watermark';
import logo from '../../assets/footer-logo.png';
import {
  CustomFooter,
  MobileMenuWrapper,
  MobileNavigationFooter,
} from './styled';

const Footer = ({ items }: { items: NavigationListItem[] }) => {
  const isMobile = window.innerWidth < 500;
  return (
    <CustomFooter
      id='footer'
      rowCenter={
        !isMobile
          ? {
              left: <img src={logo} alt='logo' />,
              center: <NavigationList items={items} />,
              right: <HelpSectionText />,
            }
          : {
              left: null,
              center: (
                <MobileNavigationFooter>
                  <MobileMenuWrapper>
                    <img src={logo} alt='logo' />
                    <NavigationList items={items} />
                  </MobileMenuWrapper>
                  <HelpSectionText />
                </MobileNavigationFooter>
              ),
              right: null,
            }
      }
      rowBottom={{
        left: <PublicOffer />,
        center: '',
        right: <Watermark />,
      }}
    />
  );
};

export default Footer;
