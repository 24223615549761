import React, { FC } from 'react';
import { Text } from '@consta/uikit/Text';
import { Button } from '@consta/uikit/Button';
import { IconDocFilled } from '@consta/uikit/IconDocFilled';
import { Card } from '@consta/uikit/Card';
import StockAddresses from 'features/main/stockAddresses';
import Margin from 'shared/ui/components/margin';
import { TextContainer, KnowledgeBaseContainer } from './styled';

const InstructionsPage: FC = () => {
  const isMobile = window.innerWidth < 640;
  const isTablet = window.innerWidth < 1100 && window.innerWidth >= 640;
  const smallScreen = isMobile || isTablet;

  return (
    <>
      <Margin mb={smallScreen ? isMobile ? 24 : 28 : 32}>
        <Text size={smallScreen ? isMobile ? '2xl' : '3xl' : '4xl'} view='primary' weight='medium'>
          Инструкция
        </Text>
      </Margin>
      <StockAddresses hideLink />
      {/* <Margin
        mt={smallScreen ? isMobile ? 80 : 120 : 160}
        mb={smallScreen ? isMobile ? 24 : 28 : 32}
      >
        <Text size={smallScreen ? isMobile ? 'l' : '2xl' : '3xl'} view='primary' weight='medium'>
          Ознакомьтесь с возможностями LTL Cargo
        </Text>
      </Margin>
      <VideoContainer>
        <ReactPlayer
          width='100%'
          height='100%'
          controls
          url='http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerFun.mp4'
        />
      </VideoContainer> */}
      <Margin
        mt={smallScreen ? isMobile ? 80 : 120 : 160}
        mb={smallScreen ? isMobile ? 24 : 28 : 32}
      >
        <Text size={smallScreen ? isMobile ? 'l' : '2xl' : '3xl'} view='primary' weight='medium'>
          Список товаров запрещенных к выкупу
        </Text>
      </Margin>
      <TextContainer>
        <Text view='secondary' size={smallScreen ? 'xs' : 's'}>
          Любые товары стоимостью больше 200 евро подлежат обязательному таможенному оформлению без
          применения упрощенной формы) т.е Вы будете оплачивать НДС, импортную пошлину, работу брокера,
          таможенные сборы, а также по необходимости Вам нужно будет предоставить все необходимые документы
          для таможенного оформления (сертификаты, инвойсы и тп.)
        </Text>
        <Text view='secondary' size={smallScreen ? 'xs' : 's'}>
          В МПО (Международные Почтовые Отправления) мы не перевозим следующие категории товаров:
          <ul>
            <li>Оружие огнестрельное, сигнальное, пневматическое, газовое, боеприпасы, холодное (включая метательное), электрошоковые устройства и искровые разрядники, а также основные части огнестрельного оружия;</li>
            <li>Наркотические средства, психотропные, сильнодействующие, радиоактивные, взрывчатые, едкие, легковоспламеняющиеся (включая парфюмерию на основе спиртов) и другие опасные вещества;</li>
            <li>Животные и растения, а также их семена и споры;</li>
            <li>Сжатые газы, аэрозоли и вещи их содержащие;</li>
            <li>Денежные знаки и их эквиваленты(кредитные карты, непогашенные чеки, сберегательные книжки, авиа и ж/д билеты с открытой датой), а также акции, и.т.п.;</li>
            <li>Скоропортящиеся продукты питания;</li>
            <li>Художественные ценности, картины, иконы, антиквариат;</li>
            <li>Порнографические материалы;</li>
            <li>Прах и человеческие органы;</li>
            <li>Инфекционные и биологичекие образцы;</li>
            <li>Почтовые марки;</li>
            <li>Лекарственные средства;</li>
            <li>Ювелирные изделия;</li>
            <li>Художественные ценности, картины, иконы, антиквариат.</li>
          </ul>
        </Text>
        <Text view='secondary' size={smallScreen ? 'xs' : 's'}>
          Не перевозим в карго отправлениях:
          <ul>
            <li>Грузы, у которых нет сопроводительных документов;</li>
            <li>Наркотические средства, психотропные, сильнодействующие, радиоактивные вещества;</li>
            <li>Животные и растения;</li>
            <li>Денежные знаки и их эквиваленты(кредитные карты, непогашенные чеки, сберегательные книжки, авиа и ж/д билеты с открытой датой), а также акции, и т.п.;</li>
            <li>Порнографические материалы;</li>
            <li>Инфекционные и биологичекие образцы;</li>
            <li>Почтовые марки;</li>
            <li>Художественные ценности, картины, иконы, антиквариат.</li>
            <li>Дополнительно не перевозим из Китая:</li>
            <li>Посылки содержащие батарейки и аккумуляторы ЛЮБЫХ видов.</li>
          </ul>
        </Text>
      </TextContainer>
      <Margin
        mt={smallScreen ? isMobile ? 24 : 28 : 32}
        mb={smallScreen ? isMobile ? 80 : 100 : 120}
      >
        <Button
          as='a'
          href='https://ltl-cargo.com/document'
          target='_blank'
          className='buttonBlack'
          size='s'
          form='round'
          width={isMobile ? 'full' : 'default'}
          label='Правила ввоза товаров для личного пользования '
          iconLeft={IconDocFilled}
        />
      </Margin>
      <Margin
        mb={smallScreen ? isMobile ? 80 : 100 : 120}
      >
        <KnowledgeBaseContainer>
          <Card shadow={false}>
            <Text size={smallScreen ? isMobile ? 'l' : '2xl' : '3xl'} weight='medium' align='center'>
              Ответы на самые популярные вопросы вы можете найти в нашей базе знаний
            </Text>
            <Button
              as='a'
              href='https://ltl-cargo.com/base'
              target='_blank'
              view='secondary'
              form='round'
              size='s'
              label='Перейти в базу знаний' 
            />
          </Card>
        </KnowledgeBaseContainer>
      </Margin>
    </>
  );
};

export default InstructionsPage;