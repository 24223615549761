import React from 'react';
import { Text } from '@consta/uikit/Text';
import { Card } from '@consta/uikit/Card';
import { Button } from '@consta/uikit/Button';
import { IconEdit } from '@consta/uikit/IconEdit';
import { IconTrash } from '@consta/uikit/IconTrash';
import Flag from 'entities/countries';
import { AddressType } from 'types';
import Margin from 'shared/ui/components/margin';
import { countriesMap } from 'shared/constants/countries.constants';
import { AddressActionsWrapper, AddressWrapper, FlagWrapper } from './styled';

type AddressItemProps = {
  item: AddressType;
  onDelete: (id: number) => void;
  onEdit: (item: AddressType) => void;
};

const AddressItem = ({ item, onDelete, onEdit }: AddressItemProps) => {
  const itemDisabled = item?.isUsed;
  return (
    <Card verticalSpace='l' horizontalSpace='l' shadow={false}>
      <AddressWrapper>
        <FlagWrapper>
          <Flag variant={item.country} />
        </FlagWrapper>
        <Margin ml={16}>
          <Text size='m'>
            {countriesMap[item.country]}, г. {item.address.city}, ул.{' '}
            {item.address.street} {item.address.home}, кв. {item.address.flat}
          </Text>
        </Margin>
      </AddressWrapper>
      <AddressActionsWrapper>
        <Button
          className='buttonBlack'
          onlyIcon
          size='s'
          form='round'
          iconLeft={IconEdit}
          disabled={itemDisabled}
          onClick={() => onEdit(item)}
        />
        <Button
          className='buttonBlack'
          onlyIcon
          size='s'
          form='round'
          iconLeft={IconTrash}
          disabled={itemDisabled}
          onClick={() => onDelete(item.id)}
        />
      </AddressActionsWrapper>
    </Card>
  );
};

export default AddressItem;
